import React from 'react'
 import SVG179 from "../../images/shape/179.svg"
 import SVG180 from "../../images/shape/180.svg"
 import SVG181 from "../../images/shape/181.svg"
 import SVG182 from "../../images/shape/182.svg"
 import SVG183 from "../../images/shape/183.svg"
 import SVG184 from "../../images/shape/184.svg"
 import SVG185 from "../../images/shape/185.svg"
 import SVG186 from "../../images/shape/186.svg"
 
 import SVG188 from "../../images/shape/188.svg"

const ThankYou = () => {
  return (
    <div className="h-100 fdfgdf">
    <div className="full-height-layout d-flex align-items-center">
      <div className="coming-soon-content font-gordita">
        <h1>Thank You!</h1>
        <div className="row">
          <div className="col-lg-9 m-auto">
            <p>Our team is in touch with you soon!</p>
          </div>
        </div>

        <img src={SVG179} alt="" className="shapes shape-one" />
        <img src={SVG180} alt="" className="shapes shape-two" />
        <img src={SVG181} alt="" className="shapes shape-three" />
        <img src={SVG182} alt="" className="shapes shape-four" />
        <img src={SVG183} alt="" className="shapes shape-five" />
        <img src={SVG184} alt="" className="shapes shape-six" />
        <img src={SVG185} alt="" className="shapes shape-seven" />
        <img src={SVG186} alt="" className="shapes shape-eight" />
        <img src={SVG180} alt="" className="shapes shape-nine" />
        <img src={SVG188} alt="" className="shapes shape-ten" />
      </div> {/* /.coming-soon-content */}
    </div> 
  </div>
  )
}

export default ThankYou