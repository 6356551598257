import React from 'react'
import Header from '../../../../../src/components/Common/Header'
import Footer from '../../../../../src/components/Common/Footer'
import HeroBannerSixNativeTech from '../components/HeroBannerSixNativeTech'
import ContainerMiniLeftNativeTech from '../components/ContainerMiniLeftNativeTech'
import ContainerNativeTech from '../components/ContainerNativeTech'
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import TestimonialSection from "../components/TestimonialSection";
import androidDesign from "../../../../images/assets/android12.png"
import softPNG from "../../../../images/assets/soft.png";
// import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
// import PNG890 from "../../../../images/assets/890.png";
// import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import img00 from "../../../../images/assets/00.png"
import img1209 from "../../../../images/assets/1209.png"
import img28 from "../../../../images/assets/28.png"

import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const SocialMediaMarketing = () => {
    const tagLines = 'Our Social Media Marketing Excellence';
  const title = 'A Potent Social Media Marketing Company Delivering Polished Outcome';
  const description = [
                        `A social media marketing company specializes in creating, managing, and
                         implementing marketing strategies on various social media platforms to 
                         help businesses increase their online presence, engage with their target
                          audience, and achieve their marketing goals. Strategy Development, 
                          Content Creation, Paid Advertising, Analytics and Reporting, 
                          Campaign Maker, etc, are part of our social media marketing strategy.  `
                       ];
  const services = {
    title: ' Next-Gen Social Media Marketing Services With Professional Excellence',
    tagLines: ' Social Media Marketing With Professional Excellence',
    items: [
      {
        title: 'Enterprise Social Media Marketing App Development',
        imageSrc: softPNG,
        imageAlt: 'Best Enterprise Android App Development Nearby Ilford UK'
      },
      {
        title: ' Social Media App development',
        imageSrc: img00,
        imageAlt: 'Android Full-cycle App Development Near Me Ilford UK'
      },
      {
        title: 'Social Media ',
        imageSrc: img1209,
        imageAlt: 'Android App Migration Freelancer In Ilford UK'
      },
      {
        title: 'Social Media Marketing Applications',
        imageSrc: PNG58,
        imageAlt: 'Best Android M-commerce Applications Developer Nearby Ilford UK'
      },
      {
        title: ' App Testing',
        imageSrc: img28,
        imageAlt: 'Quality App Testing Services Provider Near Me Ilford UK'
      },
      {
        title: ' Social Media Marketing Maintenance & Support',
        imageSrc: PNG78,
        imageAlt: 'Social Media Marketing Maintenance And Support Agency In UK'
      },
      {
        title: ' Social Media Design',
        imageSrc: uiPNG,
        imageAlt: 'Best Android App UI/UX App Design Agency In Ilford UK'
      },
      {
        title: 'Social Media Marketing',
        imageSrc: PNG1221,
        imageAlt: 'Top Social Media Marketing Service Provider Nearby Ilford UK'
      }
    ],
    buttonText: 'Inquire Now',
    buttonLink: 'https://hastechsolutions.ca/contact.html'
    };  
  const stats =  [
      {
        image: PNG12,
        alt: "Top Social Media Marketing Experts Near Me Ilford UK",
        count: "20+",
        label: "SMM Experts",
      },
      {
        image: PNG81,
        alt: "Experience Freelancer For Social Media Marketing In UK",
        count: "15+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "Social Media Marketing Projects Management Services In UK",
        count: "150+",
        label: "SMM Projects Completed",
      },
      {
        image: PNG38,
        alt: "Custom Social Media Marketing Project Maker Neaby Ilford UK",
        count: "300+",
        label: "Custom SMM Projects",
      },
                 
                      ];   

      const heading = "Raising The Bar For Social Media Marketing";
      
      const testimonials = [
        {
          company: "Anyvision",
          content:
            "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
          image: MopePNG,
          alt: "Social Media Marketing Developers In Oakville, Ilford UK",
        },
        {
          company: "Enterprise E-Commerce",
          content:
            "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
          image: PushPNG,
          alt: "Best Social Media Marketing Freelancer In Oakville, Ilford UK",
        },
        {
          company: "Glitty",
          content:
            "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
          image: PNG876,
          alt: "Best Social Media Marketing Agency In Oakville, Ilford UK",
        },
        {
          company: "Rogi",
          content:
            "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
          image: SinglePNG,
          alt: "Best Social Media Marketing Company In Oakville, Ilford UK",
        },
                     ];  
  return (
    <>
      <Header />
      
      <HeroBannerSixNativeTech
      title="Social Media Marketing"
      description="With thousands of Social Media Marketing companies in the market but you need a professional Social Media Marketing company to launch your businesses, products your presence. By adding the element of WOW, we suggest you a quality and effective social media strategy to get engagement and traffic. We take you beyond the bounds of excellence. "
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={androidDesign}
      imageAlt="Best Social Media Marketing Agency In Ilford UK"
    />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer/>
    </>
  )
}

export default SocialMediaMarketing