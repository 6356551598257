
import React from 'react';

const HeroBannerSixNativeTech = ({ title, description, buttonText, buttonLink, imageSrc, imageAlt }) => {
  return (
    <div className="hero-banner-six">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="inner-left">
              <div className="inner-head-title content__paragraph" data-splitting="true">
                <h1>
                  <span>{title}</span>
                  <br />
                  Development
                </h1>
              </div>
              <div className="inner-about">{description}</div>
              {/* <button className="cmr-solid-btn hvr-shutter-out-horizontal">
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
                <a className="button__link" href={buttonLink} data-toggle="modal" data-target="#contactModal">
                  {buttonText}
                </a>
              </button> */}
            </div>
          </div>
          <div className="col-md-5">
            <div className="inner-right">
              <img src={imageSrc} className="iphone" alt={imageAlt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBannerSixNativeTech;
