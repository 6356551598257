import React from "react";

const ModalComponent = ({ togglePopup }) => {
  return (
    <div>
      <div
        className="modal fade modal-contact-popup-one"
        id="contactModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contactModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <main className="main-body modal-content clearfix">
          <button type="button" className="close" onClick={togglePopup} data-dismiss="modal">
</button>

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “We never dreamed about success. We worked for it.”
                    </blockquote>
                    <span className="bio">Hastech</span>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="right-side">
              <h2 className="form-title">Contact us</h2>
              <form
                id="conatct"
                method="post"
                action="send-mail.php"
                data-toggle="validator"
              >
                <div className="messages"></div>
                <div className="row controls">
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-15">
                      <label>Name*</label>
                      <input
                        type="text"
                        placeholder="Your Name"
                        name="Fname"
                        required="required"
                        data-error="Name is required."
                        className="form-control"
                      />

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-35">
                      <label>Email*</label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        required="required"
                        data-error="Valid email is required."
                        className="form-control"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group-meta form-group mb-25">
                      <label>Message*</label>
                     
                      <textarea
                        placeholder="Your message"
                        name="message"
                        required="required"
                        data-error="Please,leave us a message."
                        className="form-control"
                      ></textarea>

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="theme-btn-seven hover-reverse-gr-bg-one w-100"
                      name="submit"
                      id="contact-submit"
                      data-submit="...Sending"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;