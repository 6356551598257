import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/angular.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGplugin from "../../../../images/assets/plugin.png";
// import extPNG from "../../../../images/assets/ext.png";
// import uiPNG from "../../../../images/assets/ui.png";
// import PNG58 from "../../../../images/assets/58.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG984 from "../../../../images/assets/984.png";
// import PNG78 from "../../../../images/assets/78.png";
// import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGsoft from "../../../../images/assets/soft.png";
import PNGsoftware from "../../../../images/assets/software.png";
import PNGecom from "../../../../images/assets/ecommerce.png";
import PNGsocial from "../../../../images/assets/social.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import PNGform from "../../../../images/assets/form.png";



const AngularPage = () => {
    const tagLines = "Our Angular Excellence";
  const title = "Leading Angular Development Company";
  const description = `We provide an Angular development approach, combined with our extensive experience in
  MVVM and MVC. Our single page applications are integrated with the aspects of
  both MVVM and MVC. Angular developers who have a deep and broadened experience
  into HTML can leverage the simplified component base framework completely to 
  develop a web application with declarative UI and modular structure. We can 
  help you achieve your business goals by providing Angular development services
  and helping you see your technical approaches transforming into more successful ones.`;
  const services = {
    title: " Angular App Development Services",
    tagLines: " Top Level Angular Development With Professional Excellence",
    items: [
      {
        title: "Angular Single-Page App",
        imageSrc: PNGform,
        imageAlt:
          "Angular single-page app development",
      },
      {
        title: "Plugin Development",
        imageSrc: PNGplugin,
        imageAlt: "Plugin Development With Angular Framework In Ilford UK",
      },
      {
        title: "Angular Mobile App Development",
        imageSrc: PNGsoft,
        imageAlt: "Angular mobile app development Service Provider Nearby Ilford UK",
      },
      {
        title: "Angular Software Development ",
        imageSrc: PNGsoftware,
        imageAlt: "Angular software development Agency In UK",
      },
      {
        title: " Angular Support & Maintenance",
        imageSrc: PNG890,
        imageAlt:
          "Angular Support & Maintenance Provider Neaerby Ilford UK",
      },
      {
        title: " E-Commerce Application Development",
        imageSrc: PNGecom,
        imageAlt: "E-Commerce Application Development Agency In Ilford UK",
      },
      {
        title: "Custom Web Application Development",
        imageSrc: PNG984,
        imageAlt: "Custom Web Application Development Comapny In Ontrio UK",
      },
      {
        title: "   Social And Interactive App",
        imageSrc: PNGsocial,
        imageAlt: "Social And Interactive App Developer In Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "AngularJs Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "AngularJs Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom AngularJs Projects",
    },
  ];

  const heading = "Raising The Bar For Angular Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
         <Header />
      <HeroBannerSixNativeTech
        title="Angular Development"
        description="Drive your business growth with intuitive applications and dynamic single-page web applications from top Angular Development Company. Our experienced Angular developers build applications with optimal structure while delivering the best of Angular development services."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Angular App Development Company In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      {/* <TestimonialSection testimonials={testimonials} />; */}
      <Footer />
    </>
  )
}

export default AngularPage