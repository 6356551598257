import React from "react";
import logo17 from "../../images/logo/logo-17.png";
import logo18 from "../../images/logo/logo-18.png";
import logo19 from "../../images/logo/logo-19.png";
import logo20 from "../../images/logo/logo-20.png";
import img154 from "../../images/shape/154.svg";

const FeatureFourteen = () => {
  const style1 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "1020px",
    height: "1020px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0
  };
  const style3 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  };
  const style4 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "1020px",
    height: "1020px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0
  };
  const style5 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  };
  const style6 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "1020px",
    height: "1020px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0
  };
  const style7 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  };
  const style8 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "1020px",
    height: "1020px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0
  };
  return (
    <>
      <div className="fancy-feature-fourteen mt-150 pt-80 md-mt-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-8">
              <div className="title-style-six">
                <h6 style={{ color: "#e43753", fontSize: "15px" }}>
                  Technologies
                </h6>
                <h2>
                  Connect App <br /> with the <span>Software</span> you use.
                </h2>
                <p className="text-xs pr-5 mr-5">
                    Web applications are computer programs that are transported across the internet
                  to be used by people. They allow users to access, manage, and
                  share information more easily than traditional software
                  programs.
                </p>
              </div>
            </div>
            <div className="col-lg-5 ml-auto">
              <div className="right-side md-mt-60">
                <div className="row">
                  <div className="col-md-6">
                    <div className="logo-meta lg d-flex align-items-center justify-content-center js-tilt">
                      <img src={logo17} alt="HTML Developer In Ilford" />
                      <div className="js-tilt-glare" style={style1}>
                        <div className="js-tilt-glare-inner" style={style2}></div>
                      </div>
                    </div>
                    <div className="logo-meta d-flex align-items-center justify-content-center js-tilt">
                      <img
                        src={logo18}
                        alt="Swift Programming Developer In Ilford"
                      />
                      <div className="js-tilt-glare" style={style3}>
                        <div className="js-tilt-glare-inner" style={style4}></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="logo-meta mt-40 d-flex align-items-center justify-content-center js-tilt">
                      <img
                        src={logo19}
                        alt="Top Javascript Developer In Ilford"
                      />
                      <div className="js-tilt-glare" style={style5}>
                        <div className="js-tilt-glare-inner" style={style6}></div>
                      </div>
                    </div>
                    <div className="logo-meta lg d-flex align-items-center justify-content-center js-tilt">
                      <img
                        src={logo20}
                        alt="Kotlin Freelance Developer In Ilford"
                      />
                      <img src={img154} alt="" className="shapes shape-one" />
                      <div className="js-tilt-glare" style={style7}>
                        <div className="js-tilt-glare-inner" style={style8}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureFourteen;
