import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import EcomPNG from "../../../../images/assets/ecommerce.png";
import LaravelPNG from "../../../../images/assets/laravel.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNGmb34 from "../../../../images/assets/mb34.png";
import FormPNG from "../../../../images/assets/form.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const LaravelPage = () => {
  const tagLines = "Our Laravel Excellence";
  const title =
    "With Agile Methodology At The Core Our Laravel Development Services Are At The Best";
  const description = `We offer guaranteed Laravel development solutions that embrace strong brand presence with 
  feature-packed web applications.
  Developing a great design is definitely important, but it's also important to integrate it properly with backend functions and ensure its 
  performance is top-notch. Our skilled Laravel developers do it all with excellence.`;
  const services = {
    title: "Laravel Development Services",
    tagLines: "Laravel Development With Professional Excellence",
    items: [
      {
        title: "Laravel Mobile App Development",
        imageSrc: softPNG,
        imageAlt:
          "Laravel Mobile App Development",
      },
      {
        title: "Larval E-commerce Development",
        imageSrc: EcomPNG,
        imageAlt: "Customized E-Commerce Development Agency In Ilford UK",
      },
      {
        title: "Customized Laravel Development Services",
        imageSrc: FormPNG,
        imageAlt: "Customized Laravel Development Services",
      },
      {
        title: " Laravel Consulting & Strategy",
        imageSrc: PNG58,
        imageAlt:
          "Laravel Consulting & Strategy",
      },
      {
        title: "Maintenance And Support",
        imageSrc: PNG890,
        imageAlt:
          "Maintenance And Support",
      },
      {
        title: "Laravel Enterprise Solutions",
        imageSrc: PNG78,
        imageAlt:
          "Laravel Enterprise Solutions",
      },
      {
        title: "Website Migration using Laravel",
        imageSrc: PNG984,
        imageAlt: "Website Migration using Laravel",
      },
      {
        title: "Laravel Extension Development",
        imageSrc: PNG1221,
        imageAlt:
          "Laravel Extension Development",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Laravel Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Laravel Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Laravel Projects",
    },
  ];

  const heading = "Raising The Bar For Laravel Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Laravel"
        description="Harness the power of Laravel framework with our Laravel development services to meet the full spectrum
         of your business requirements from a small portal to large enterprise solution with complex and thriving challenges.With 
         the strong virtue and high-experience of our
         Laravel developers develop websites with uncompromising performance and strong backend."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={LaravelPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default LaravelPage;
