import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img84 from "../../images/icon/84.svg";
import img155 from "../../images/shape/155.svg";
import img156 from "../../images/shape/156.svg";
import img157 from "../../images/shape/157.svg";
import img158 from "../../images/shape/158.svg";

const ClientFeedbackSlider = () => {

  const renderArrowPrev = () => null; 
  const renderArrowNext = () => null; 


  return (
    <>
      <div
        className="client-feedback-slider-five mt-200 md-mt-80"
        id="feedback"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-10 col-md-9 m-auto">
              <img
                src={img84}
                alt="Web Development Quotation"
                className="icon m-auto"
              />
              <div className="title-style-six text-center mt-25">
                <h2>
                  Check what <span>Customer</span> say about us.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 col-lg-10 m-auto">
              <div
                className="slider-container"
                style={{ margin: 0, padding: 0 }}
              >
                <Carousel
                  showThumbs={false}
                  autoPlay
                  infiniteLoop
                  interval={2000}
                  showStatus={false}
                  showIndicators={false}
                  renderArrowPrev={renderArrowPrev}
                  renderArrowNext={renderArrowNext}
                >
                  <div className="item">
                    <p>
                      I worked with HAStech Solutions Company for my company
                      website design. They did an amazing job and the end result
                      was better than I could have imagined!
                    </p>
                    <div className="name">Harvey</div>
                    <span>Fat2fuel</span>
                  </div>
                  <div className="item">
                    <p>
                      I have had the pleasure of working with Web development
                      company throughout my career. They always seem to be high
                      quality and very genuine, which is a great testament to
                      their abilities as developers.
                    </p>
                    <div className="name">Baldu</div>
                    <span>Link2scale</span>
                  </div>
                  <div className="item">
                    <p>
                      I worked with HAStech Solutions Company for my company
                      website design. They did an amazing job and the end result
                      was better than I could have imagined!
                    </p>
                    <div className="name">Zach</div>
                    <span>Zachhines</span>
                  </div>
                  <div className="item">
                    <p>
                      I have had the pleasure of working with Web development
                      company throughout my career. They always seem to be high
                      quality and very genuine, which is a great testament to
                      their abilities as developers.
                    </p>
                    <div className="name">Wayne</div>
                    <span>stairliftsplymouth</span>
                  </div>
                </Carousel>
              </div>
              <ul className="d-flex justify-content-center slider-arrow mt-40">
                <li className="prev_f slick-arrow">
                  <i className="flaticon-right-arrow"></i>
                </li>
                <li className="next_f slick-arrow">
                  <i className="flaticon-right-arrow"></i>
                </li>
              </ul>
              <img src={img156} alt="" className="shapes shape-one" />
              <img src={img157} alt="" className="shapes shape-two" />
              <img src={img158} alt="" className="shapes shape-three" />
            </div>
          </div>
        </div>
        <div className="circle-area">
          <img src={img155} alt="" className="main-img" />
        </div>
      </div>
    </>
  );
};

export default ClientFeedbackSlider;
