import React from "react";
import approach from "../../images/assets/approach.gif"
import mark from "../../images/assets/mark.gif"
import android from "../../images/assets/android.svg"
import ios from "../../images/assets/ios.svg"
import flutter from "../../images/assets/flutter.svg"
import kotlin from "../../images/assets/kotlin.svg"
import ionic from "../../images/assets/ionic.svg"
import react from "../../images/assets/react.svg"
import xamarin from "../../images/assets/xamarin.svg"
import pwa from "../../images/assets/pwa.svg"
import ipad from "../../images/assets/ipad.svg"
import mobile from "../../images/assets/mobile.svg"
import laravel from "../../images/assets/laravel.svg"
import node from "../../images/assets/node.svg"
import php from "../../images/assets/php.svg"
import yii from "../../images/assets/yii.svg"
import code from "../../images/assets/code.svg"
import magneto from "../../images/assets/magneto.svg"
import zend from "../../images/assets/zend.svg"
import angular from "../../images/assets/angular.svg"
import reactjs from  "../../images/assets/reactjs.svg"
import vue from  "../../images/assets/vue.svg"
import metor from "../../images/assets/metor.svg"
import express from "../../images/assets/express.svg"
import hastechStuff from "../../images/assets/hastech-stuff.png"
import img163 from "../../images/shape/163.svg"
import img164 from "../../images/shape/164.svg"
import symphony from "../../images/assets/symphony.svg"
import responsive from  "../../images/assets/responsive.svg"
import psd from "../../images/assets/psd.svg"
import FancyShortBanner from "../Home/FancyShortBanner";
import { NavLink } from "react-router-dom";



const AboutMisVis = () => {
  return (
    <>
      <div className="mis-vis">
        <div className="container cmr-tw-adv">
          <div className="mlr-auto">
            <div className="row">
              <div className="col-sm-6">
                <div className="tw-plate tw-two">
                  <div className="cmr-tip-des">
                    <p className="cmr-point mt-3">
                      <span style={{ fontFamily: "Roboto, sans-serif" }}>
                        Mission
                      </span>
                    </p>
                  </div>

                  <div className="slide-plate">
                    <div className="slide-detail">
                      <p>
                        HASTech empowers your business by enhancing overall
                        effectiveness and efficiency using next generation
                        technology. Our goal is to help you achieve your goals
                        by providing you with the tools you need to visualize
                        and achieve your economic and professional success..
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="tw-plate tw-three">
                  <div className="cmr-tip-des">
                    <p className="cmr-point mt-3">
                      <span style={{ fontFamily: "Roboto, sans-serif" }}>
                        Vision
                      </span>
                    </p>
                  </div>

                  <div className="slide-plate">
                    <div className="spriteOne slide-number slide-act-one"></div>

                    <div className="slide-detail">
                      <p>
                        To consistently lead technology innovation and provide
                        technology software services and solutions to global
                        clientele with exceptional quality, sharp turn around
                        time and cost effectiveness.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>

        <div className="cmr-tw-adv">
          <div>
            <div className="tw-lefter">
              <div className="right-slant">
                <div className="anmtn-element in-view">
                  <div className="animated-cuts-right">
                    <div className="right-bg"></div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row m-0">
                  <div className="col-sm-6 text-right">
                    <img
                      src={approach}
                      alt="Best Web And App Development Agency In Ilford UK"
                    />
                  </div>

                  <div className="col-sm-6">
                    <div className="tw-plate tw-lg tw-four">
                      <div className="cmr-tip-des">
                        <p className="cmr-point mt-3">
                          <span style={{ fontFamily: "Roboto, sans-serif" }}>
                            Our Approach
                          </span>
                        </p>
                      </div>

                      <div className="slide-plate">
                        <div className="slide-detail">
                          <p>
                            Our approach is customer-centric and we believe that
                            our passion for our work is what drives us to be the
                            best. We are always looking for ways to improve our
                            products and services, in order to provide you with
                            the best possible experience. We are completely
                            relationship-oriented and our focus is always on
                            building long-term relationships with our customers.
                            We believe that our approach is the best possible
                            way to provide you with the best possible service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-righter">
              <div className="left-slant">
                <div className="anmtn-element in-view">
                  <div className="animated-cuts-left">
                    <div className="left-bg"></div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row-2 m-0">
                  <div className="col-sm-6">
                    <div className="tw-plate tw-one">
                      <div className="cmr-tip-des">
                        <p className="cmr-point mt-3">
                          <span style={{ fontFamily: "Roboto, sans-serif" }}>
                            Mission-Driven Team
                          </span>
                        </p>
                      </div>

                      <div className="slide-plate">
                        <div className="spriteOne slide-number slide-act-one"></div>

                        <div className="slide-detail">
                          <p>
                            The heartbeat of HASTech is our people. We are more
                            than just technocrats, designers, developers,
                            project managers, digital marketers & QA experts. We
                            are passionate people who encourage people to share
                            their authentic thoughts on the table, which enables
                            us to discuss, debate and create unique concepts,
                            ideas and work results that other companies can't.
                            Our team is armed with talents that ensure we
                            implement the best technology services for global
                            businesses
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 text-left">
                    <img
                      src={mark}
                      alt="Best Digital Marketing Agency In Ilford UK"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="meet-team-sec pt-5 mt-5 mb-5">
          <div className="container-meet">
            <div className="text-center">
              <div className="sec-title">
                <h3 className="fs-24 mb-4">
                  You Just Need A Mission-Driven Team To Deliver Mind-Boggling
                  Results
                </h3>
              </div>

              <button className="cmr-solid-btn hvr-shutter-out-horizontal get-center">
                Meet our team
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="sec-title mb-5 mobile-custom-spacing-bottom-4">
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            <span>All about our</span>
          </p>

          <h3 style={{ textAlign: "center" }}>
            <span>Technology</span> Stack
          </h3>

          <div className="container">
            <ul
              className="nav nav-tabs nav-fill wizard"
              id="myTab"
              role="tablist"
              style={{ marginBottom: "40px" }}
            >
              <li className="nav-item active">
                <a
                  href="#home"
                  className="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <span className="step">1</span>Mobile
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="#profile"
                  className="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  <span className="step">2</span>Backend
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="#contact"
                  className="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  <span className="step">3</span>Frontend
                </a>
              </li>
            </ul>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row justify-content-center mt-5 mobile-custom-spacing-top">
                  <div
                    className="col-lg-2 col-md-2 col-sm-3 col-4"
                    style={{ marginBottom: "20px" }}
                  >
                    <NavLink to="/android">
                      <img
                        src={android}
                        alt="Android icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-10px" }}>
                        Android
                      </h5>
                    </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/iphone">
                    
                      <img
                        src={ios}
                        alt="Ios icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "10px" }}>
                        iOS
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                   
                    <NavLink to="/flutter">
                      <img
                        src={flutter}
                        alt="Flutter icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Flutter</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/kotlin">
                    
                      <img
                        src={kotlin}
                        alt="kotlin icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Kotlin</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/ionic">
                   
                      <img
                        src={ionic}
                        alt="ionic icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Ionic</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/reactnative">
                   
                      <img
                        src={react}
                        alt="React icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">React</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="xamarin">
                    
                      <img
                        src={xamarin}
                        alt="xamarin icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Xamarin</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/pwa">
                   
                      <img
                        src={pwa}
                        alt="Progressive Web App icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">PWA</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/ipad">
                    
                      <img
                        src={ipad}
                        alt="ipad app icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">iPad</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/android">
                    
                      <img
                        src={mobile}
                        alt="mobile app icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Mobile</h5>
                      </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row justify-content-center mt-5 mobile-custom-spacing-top">
                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/laravel">
                    
                      <img
                        src={laravel}
                        alt="laravel icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Laravel</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/nodejs">
                  
                      <img
                        src={node}
                        alt="nodejs icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-6px" }}>
                        NodeJs
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/php">
                  
                      <img
                        src={php}
                        alt="php icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">PHP</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/yii">
                   
                      <img
                        src={yii}
                        alt="yii framwork icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "13px" }}>
                        Yii
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/codeigniter">
                   
                      <img
                        src={code}
                        alt="codeignitor icon"
                        width="56"
                        height="69"
                      />

                      <h5 style={{ marginLeft: "-27px" }} className="android">
                        Codeigniter
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/">
                    
                      <img
                        src={magneto}
                        alt="magento icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-15px" }}>
                        Magento
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/zend">
                   
                      <img
                        src={zend}
                        alt="zend icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android">Zend</h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/symfony">
                    
                      <img
                        src={symphony}
                        alt="symfony icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-15px" }}>
                        Symfony
                      </h5>
                      </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="row justify-content-center mt-5 mobile-custom-spacing-top">
                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/angular">
                    
                      <img
                        src={angular}
                        alt="angular icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-13px" }}>
                        Angular
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/reactjs">
                  
                      <img
                        src={reactjs}
                        alt="reactjs icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-11px" }}>
                        ReactJs
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/vuejs">
                    
                      <img
                        src={vue}
                        alt="vuejs icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-3px" }}>
                        VueJs
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/meteorjs">
                  
                      <img
                        src={metor}
                        alt="meteorjs icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-16px" }}>
                        MetorJs
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/expressjs">
                    
                      <img
                        src={express}
                        alt="expressjs icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-18px" }}>
                        ExpressJs
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/responsivedesign">
                   
                      <img
                        src={responsive}
                        alt="responsive icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "-28px" }}>
                        Responsive
                      </h5>
                      </NavLink>
                  </div>

                  <div className="col-lg-2 col-md-2 col-sm-3 col-4">
                  <NavLink to="/psdtohtml">
                  
                      <img
                        src={psd}
                        alt="psd icon"
                        width="56"
                        height="69"
                      />

                      <h5 className="android" style={{ marginLeft: "5px" }}>
                        PSD
                      </h5>
                      </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FancyShortBanner/>
      </div>
    </>
  );
};

export default AboutMisVis;
