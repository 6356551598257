import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import ResponsivePNG from "../../../../images/assets/responsive.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import Design43 from "../../../../images/assets/design43.png";

const DesignPrototypingPage = () => {
  const tagLines = "Our Design Prototyping Excellence";
  const title =
    "Design Prototyping Services Designed To Lower Your Risk";
  const description = `Win Software development is wheels within wheels process that needs better understanding, communication and 
  continuous experiments. It can turn out into a costly, time-killing and aggravating endeavor if you develop five wrong versions of a website to understand that the sixth version is ideal. We know how annoying this pain can be. So, We can help you validate
   how your web platform or mobile application will be an asset for users and how your software will be a delight for users.`;
  const services = {
    title: "Design Prototyping Development Services",
    tagLines: " Design Prototyping Development With Professional Excellence",
    items: [
      {
        title: "HTML Prototyping",
        imageSrc: softPNG,
        imageAlt: "HTML Prototyping",
      },
      {
        title: "Rapid Prototyping",
        imageSrc: extPNG,
        imageAlt: "Rapid Prototyping",
      },
      {
        title: "Digital Prototyping",
        imageSrc: uiPNG,
        imageAlt: "Digital Prototyping",
      },
      {
        title: "Wireframe Prototyping",
        imageSrc: PNG58,
        imageAlt: "Wireframe Prototyping",
      },
      {
        title: "Storyboard Prototyping",
        imageSrc: PNG890,
        imageAlt: "Storyboard Prototyping",
      },
      {
        title: "Product Prototype Design",
        imageSrc: PNG78,
        imageAlt: "Product Prototype Design",
      },
      {
        title: "Prototype Integration",
        imageSrc: PNG984,
        imageAlt: "Prototype Integration",
      },
      {
        title: "Responsive Prototyping",
        imageSrc: PNG1221,
        imageAlt: "ResponsivePrototyping ",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Design Prototyping Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Design Prototyping Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Design Prototyping Projects",
    },
  ];

  const heading = "Raising The Bar For  Design  Prototyping Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Design Prototyping"
        description="For any idea the inception of development is always rooted in risk and uncertainty. We are armed with strong prototyping principles and processes that will push you with confidence for your next big leap.
         Let’s kick-start with prototyping to boost your returns with strategies proven to take off from the beginning."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={Design43}
        imageAlt="Best & Quality Design Prototyping Development Services In UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default DesignPrototypingPage;
