import React from 'react'

const AboutHeroBanner = () => {
  return (
    <>
      <div className="hero-banner-six">
        <div className="container">
          <div className="illustration-content">
            <div className="shapes oval-one"></div>

            <div className="shapes oval-two"></div>
          </div>

          <div className="cmr-reflectors">Who We Are</div>

          <div className="row align-items-stretch">
            <div className="col-md-12">
              <div className="inner-left">
                <div className="inner-head-title text-center" data-splitting="true">
                  <div className="banner-pattern centered_lines">
                    <div className="pattern_inner">
                      <span></span>

                      <div className="line line1"></div>

                      <div className="line line2"></div>

                      <div className="line line3"></div>
                    </div>
                  </div>

                  <h1 className="font-recoleta f-s">
                    <span>Meet HAStech</span>
                  </h1>

                  <br />
                  <h5 className="software">
                    We are more than software programmers – we are critical
                    thinkers, problem-solvers, crafters of change, and
                    innovators of digital transformation. We help businesses;
                    small and big, to bring value to their end customers, beat
                    their competition and exponentially grow as a successful
                    company.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></>
  )
}

export default AboutHeroBanner