import React from "react";

const ContainerMiniLeftNativeTech = ({ tagLines, title, description }) => {
  return (
    <div className="mt-5 mobile-custom-spacing-top">
      <div className="container mini-container left-sq">
        <div className="sec-tag-lines">
          <span>{tagLines}</span>

          <h3>{title}</h3>
        </div>

        <div className="sec-description">
          <div className="sec-desc-title">
            <h6>We Help You Win</h6>
          </div>

          <div>
            <div>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerMiniLeftNativeTech;
