import React from 'react'
import Header from '../../../../../src/components/Common/Header'
import Footer from '../../../../../src/components/Common/Footer'
import HeroBannerSixNativeTech from '../components/HeroBannerSixNativeTech'
import ContainerMiniLeftNativeTech from '../components/ContainerMiniLeftNativeTech'
import ContainerNativeTech from '../components/ContainerNativeTech'
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import TestimonialSection from "../components/TestimonialSection";
import androidDesign from "../../../../images/assets/android12.png"
import softPNG from "../../../../images/assets/soft.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import img00 from "../../../../images/assets/00.png"
import img1209 from "../../../../images/assets/1209.png"
import img28 from "../../../../images/assets/28.png"

import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const SocialMediaOptimization = () => {
    const tagLines = 'Our Social Media Optimization';
  const title = 'A Potent Social Media Optimization Company Delivering Polished Services';
  const description = [
    `An organization that specializes in social media optimization develops, manages, and puts into action plans on various social media platforms to assist businesses in enhancing their online presence, connecting with their target market, and achieving their marketing objectives. `
  ];
  const services = {
    title: 'Social Media Optimization Services',
    tagLines: 'Social Media Optimization With Professional Excellence',
    items: [
      {
        title: 'Enterprise Social Media Optimization',
        imageSrc: softPNG,
        imageAlt: 'Best Enterprise Social Media Optimization Nearby Ilford UK'
      },
      {
        title: ' Social Media Optimization',
        imageSrc: img00,
        imageAlt: 'Social Media Optimization Development Near Me Ilford UK'
      },
      {
        title: 'Social Media ',
        imageSrc: img1209,
        imageAlt: 'Social Media Optimization Freelancer In Ilford UK'
      },
      {
        title: 'Social Media Applications',
        imageSrc: PNG58,
        imageAlt: 'Best Social Media Optimization Applications Developer Nearby Ilford UK'
      },
      {
        title: ' Social Media Optimization',
        imageSrc: img28,
        imageAlt: 'Social Media Optimization Provider Near Me Ilford UK'
      },
      {
        title: ' Social Media Maintenance & Support',
        imageSrc: PNG78,
        imageAlt: 'Social Media Optimization And Support Agency In UK'
      },
      {
        title: ' Social Media Design',
        imageSrc: uiPNG,
        imageAlt: 'Social Media Optimization Design Agency In Ilford UK'
      },
      {
        title: 'Social Media Optimization',
        imageSrc: PNG1221,
        imageAlt: 'Top Social Media Optimization Service Provider Nearby Ilford UK'
      }
    ],
    buttonText: 'Inquire Now',
    buttonLink: 'https://hastechsolutions.ca/contact.html'
    };  
  const stats =  [
      {
        image: PNG12,
        alt: "Top SMO Experts Near Me Ilford UK",
        count: "20+",
        label: "SMO Experts",
      },
      {
        image: PNG81,
        alt: "Experience Freelancer For SMO In UK",
        count: "15+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "SMO Projects Management Services In UK",
        count: "150+",
        label: "SMO Projects Completed",
      },
      {
        image: PNG38,
        alt: "Custom SMO Project Maker Neaby Ilford UK",
        count: "300+",
        label: "Custom SMO Projects",
      },
                 
                      ];   

      const heading = "Raising The Bar For Social Media Optimization Expertise";
      
      const testimonials = [
        {
          company: "Anyvision",
          content:
            "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
          image: MopePNG,
          alt: "Best Quality Android Application Developers In Oakville, Ilford UK",
        },
        {
          company: "Enterprise E-Commerce",
          content:
            "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
          image: PushPNG,
          alt: "Best Quality Android Application Freelancer In Oakville, Ilford UK",
        },
        {
          company: "Glitty",
          content:
            "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
          image: PNG876,
          alt: "Best Quality Android Application Agency In Oakville, Ilford UK",
        },
        {
          company: "Rogi",
          content:
            "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
          image: SinglePNG,
          alt: "Best Quality Android Application Company In Oakville, Ilford UK",
        },
                     ];  
  return (
    <>
      <Header />
      
      <HeroBannerSixNativeTech
      title="Social Media Optimization"
      description="With thousands of SMO companies in the market, you need a professional social media optimization company
                  to launch your business on social media. We take you beyond the bounds of excellence and our SMO services
                   accept nothing less. "
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={androidDesign}
      imageAlt="Best Social Media Optimization Agency In Ilford UK"
    />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer/>
    </>
  )
}

export default SocialMediaOptimization