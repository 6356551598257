import React from "react";
import { NavLink } from "react-router-dom";
import iphone from "../../images/assets/iphone.svg";
import android from "../../images/assets/android.svg";
import kotlin from "../../images/assets/kotlin.svg";
import ipad from "../../images/assets/ipad.svg";
import iconic from "../../images/assets/ionic.svg";
import react from "../../images/assets/react.svg";
import flutter from "../../images/assets/flutter.svg";
import pwa from "../../images/assets/pwa.svg";
import xamarin from "../../images/assets/xamarin.svg";
import php from "../../images/assets/php.svg";
import code from "../../images/assets/code.svg";
import laravel from "../../images/assets/laravel.svg";
import zend from "../../images/assets/zend.svg";
import yii from "../../images/assets/yii.svg";
import symphony from "../../images/assets/symphony.svg";
import ajs from "../../images/assets/ajs.png";
import njs from "../../images/assets/njs.png";
import vuejs from "../../images/assets/vujs.png";
import rjs from "../../images/assets/rjs.png";
import mjs from "../../images/assets/mjs.png";
import ejs from "../../images/assets/ejs.png";

import mad from "../../images/assets/mad.png";
import rd from "../../images/assets/rd.png";
import dp from "../../images/assets/dp.png";
import pth from "../../images/assets/pth.png";

const Cmr = () => {
  return (
    <div>
      <div className="cmr-tech-part">
        <div className="mobile-tech technology_row">
          <div className="container">
            {/* <div className="tech-layer"></div> */}
            <div className="row">
              <div className="col-md-4">
                <div className="cmr-tech-xicon mobile-tech">
                  <div className="spriteOne spt-mobile-tech postion-number-one"></div>
                </div>
                <div>
                  <h2 className="cmr-title  ">
                    <b>MOBILE</b> &nbsp;
                    <br />
                    Technologies
                  </h2>
                </div>
              </div>

              <div className="col-md-8">
                <div className="cmr-techstop">
                  <div className="row justify-content-start mb-5 techstop-list">
                    <div className="col-12">
                      <h3 className="mobile-techstop-category">
                        Native Technologies
                      </h3>
                    </div>
                    <div className="col techstop-item">
                      {/* <a href="/iphone.html" target="_blank">000
												<img src= {iphone} alt="Iphone App Developer Nedarby Oakville UK" height="69" width="56"/>
												<h5 style={{marginLeft:"-6px"}}>iPhone</h5>
											</a> */}
                      <NavLink className="nav-link" to="/iphone">
                        <img
                          src={iphone}
                          alt="Iphone App Developer Nedarby Oakville UK"
                          height="69"
                          width="56"
                        />
                        <h5 style={{ marginLeft: "-6px" }}>iPhone</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/android">
                        <img
                          src={android}
                          alt="Android App Developer Freelancer Nearby Oakville UK"
                          height="69"
                          width="56"
                        />
                        <h5 className="ander">Android</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/kotlin">
                        <img
                          src={kotlin}
                          alt="Kotlin Android App Developer Nearby Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5>Kotlin</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/ipad">
                        <img
                          src={ipad}
                          alt="Ipad App Freelancer Nearby Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5>iPad</h5>
                      </NavLink>
                    </div>
                  </div>

                  <div className="row justify-content-start mb-5 techstop-list">
                    <div className="col-12">
                      <h3 className="mobile-techstop-category">
                        Cross-Platform Technologies
                      </h3>
                    </div>

                    <div className="col techstop-item-2">
                      <NavLink className="nav-link" to="/ionic">
                        <img
                          src={iconic}
                          className="ionic"
                          alt="Ionic Developer In UK"
                          height="69"
                          width="56"
                        />
                        <h5>Ionic</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/reactnative">
                        <img
                          src={react}
                          className="react"
                          alt="React Native Development Service Provider Nearby Ilford"
                          height="69"
                          width="56"
                        />
                        <h5 className="native">React Native</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item-">
                      <NavLink className="nav-link" to="/flutter">
                        <img
                          src={flutter}
                          alt="Flutter App Developer Freelancer In Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5 style={{ marginLeft: "-5px" }}>Flutter</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item-2">
                      <NavLink className="nav-link" to="/pwa">
                        <img
                          src={pwa}
                          alt="Web App Development And Design Agency In Ilford"
                          height="69"
                          width="56"
                        />
                        <h5>PWA</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item-2">
                      <NavLink className="nav-link" to="/xamarin">
                        <img
                          src={xamarin}
                          alt="Xamarin App Developer Services In UK"
                          height="69"
                          width="56"
                        />
                        <h5 style={{ marginLeft: "-1px" }}>Xamarin</h5>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="web-tech technology_row">
          <div className="container">
            <div className="tech-layer"></div>
            <div className="row">
              <div className="col-md-4">
                <div className="cmr-tech-xicon web-tech">
                  <div className="spriteOne spt-web-tech postion-number-two"></div>
                </div>
                <div>
                  <h3 className="cmr-title  ">
                    <b>WEB</b> &nbsp;
                    <br />
                    Technologies
                  </h3>
                </div>
              </div>
              <div className="col-md-8">
                <div className="cmr-techstop">
                  <div className="row justify-content-start techstop-list">
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/php">
                        <img
                          src={php}
                          alt="Php Developer Freelancer Nearby Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5 style={{ marginLeft: "6px" }}>PHP</h5>
                      </NavLink>
                    </div>
                    <NavLink className="nav-link" to="/codeigniter">
                      <img
                        src={code}
                        style={{ marginLeft: "14px" }}
                        alt="Codeigniter Development Services Nearby Onatrio UK"
                        height="69"
                        width="56"
                      />
                      <h5 style={{ marginLeft: "-2px" }}>Codeigniter</h5>
                    </NavLink>
                    <div className="col techstop-item">
                    <NavLink className="nav-link" to="/laravel">
                      <img
                        src={laravel}
                        alt="Laravel CMS Freelancer Nearby Oakville Ilford UK"
                        height="69"
                        width="56"
                      />
                      <h5>Laravel</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/zend">
                      <img
                        src={zend}
                        alt="Zend Development Services In UK"
                        height="69"
                        width="56"
                      />
                      <h5>Zend</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/yii">
                      <img
                        src={yii}
                        alt="Yii Technology Services Nearby Ilford UK"
                        height="69"
                        width="56"
                      />
                      <h5>Yii</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/symfony">
                      <img
                        src={symphony}
                        alt="Symfony App Development Services Nearby Ilford"
                        height="69"
                        width="56"
                      />
                      <h5 style={{ marginLeft: "-5px" }}>Symfony</h5>
                    </NavLink>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="front-tech technology_row">
        <div className="container">
          <div className="tech-layer"></div>
          <div className="row">
            <div className="col-md-4">
              <div className="cmr-tech-xicon front-tech">
                <div className="spriteOne spt-front-tech postion-number-three"></div>
              </div>
              <div>
                <h3 className="cmr-title  ">
                  <b>Javascript</b> &nbsp;
                  <br />
                  Technologies
                </h3>
              </div>
            </div>
            <div className="col-md-8">
              <div className="cmr-techstop">
                <div className="row justify-content-start techstop-list">
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/angular">
                      <img
                        src={ajs}
                        alt="AngularJs Services Nearby Oakville Ilford UK"
                        height="69"
                        width="56"
                      />
                      <h5>Angular</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/nodejs">
                      <img
                        src={njs}
                        alt="NodeJS Development Services Nearby Oakville Ilford"
                        height="69"
                        width="56"
                      />
                      <h5 style={{ marginLeft: "-7px" }}>NodeJS</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/vuejs">
                      <img
                        src={vuejs}
                        alt="VueJs Services Provider Nearby Ilford"
                        height="69"
                        width="56"
                      />
                      <h5>VueJS</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/reactjs">
                      <img
                        src={rjs}
                        alt="ReactJs Services Provider Nearby Ilford UK"
                        height="69"
                        width="56"
                      />
                      <h5>ReactJS</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/meteorjs">
                      <img
                        src={mjs}
                        style={{ marginLeft: "15px" }}
                        alt="MeteorJs Services Nearby Oakville Ilford Canda"
                        height="69"
                        width="56"
                      />
                      <h5 className="metor">MeteorJS</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item">
                    <NavLink className="nav-link" to="/expressjs">
                      <img
                        src={ejs}
                        alt="ExpressJs Services Nearby Oakville Ilford"
                        style={{ marginLeft: "14px" }}
                        height="69"
                        width="56"
                      />
                      <h5 className="express">ExpressJS</h5>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cloud-tech technology_row">
        <div className="container">
          <div className="tech-layer">
            <div className="row">
              <div className="col-md-4">
                <div className="cmr-tech-xicon cloud-tech">
                  <div className="spriteOne spt-cloud-tech postion-number-four"></div>
                </div>
                <div>
                  <h3 className="cmr-title">
                    <b>CLOUD</b> &nbsp;
                    <br />
                    Technologies
                  </h3>
                </div>
              </div>

              <div className="col-md-8">
                <div className="cmr-techstop">
                  <div className="row justify-content-start techstop-list">
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/angularpage">
                        <img
                          src={ajs}
                          alt="AngularJs Services Nearby Oakville Ilford"
                          height="69"
                          width="56"
                        />
                        <h5>Angular</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/vuepage">
                        <img
                          src={vuejs}
                          alt="Vejs Services Nearby Oakville Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5>Vuejs</h5>
                      </NavLink>
                    </div>
                    <div className="col techstop-item">
                      <NavLink className="nav-link" to="/reactpage">
                        <img
                          src={rjs}
                          alt="Reactjs Services Nearby Ilford UK"
                          height="69"
                          width="56"
                        />
                        <h5>ReactJS</h5>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="UI/UX-Technologies_row">
        <div className="container">
          <div className="tech-layer"></div>
          <div className="row">
            <div className="col-md-4">
              <div className="cmr-tech-xicon uiux-tech">
                <div className="spriteOne spt-uiux-tech postion-number-six"></div>
              </div>
              <div>
                <h3 className="cmr-title  ">
                  <b>UI/UX </b> &nbsp;
                  <br />
                  Technologies
                </h3>
              </div>
            </div>

            <div className="col-md-8">
              <div className="cmr-techstop">
                <div className="row justify-content-start techstop-list">
                  <div className="col techstop-item-1">
                    <NavLink className="nav-link" to="/mobileappdesign">
                      <img
                        src={mad}
                        alt="Mobile App Design Services In Oakville UK"
                        height="69"
                        width="56"
                      />
                      <h5 className="mobile1">Mobile App Design</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item-1">
                    <NavLink className="nav-link" to="/responsivedesign">
                      <img
                        src={rd}
                        alt="Responsive Designs Services Nearby Oakville UK"
                        height="69"
                        width="56"
                      />
                      <h5 className="mobile">Responsive Design</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item-1">
                    <NavLink className="nav-link" to="/designprototyping">
                      <img
                        src={dp}
                        alt="Best Design Prototyping Services Nearby Oakville UK"
                        height="69"
                        width="56"
                      />
                      <h5 className="mobile">Design Prototyping</h5>
                    </NavLink>
                  </div>
                  <div className="col techstop-item-1">
                    <NavLink className="nav-link" to="/psdtohtml">
                      <img
                        src={pth}
                        alt="Best PSD To HTML Services Nearby Oakville UK"
                        height="69"
                        width="56"
                      />
                      <h5 className="mobile2">PSD To HTML</h5>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cmr;
