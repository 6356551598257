// import React from "react";
// import SwiperComponent from "../../SwiperComponent";

// const TestimonialSection = (props) => {
//   const { testimonials } = props;

//   return (
//     <section className="spacer">
//       <div className="testimonial-section">
//         <div className="testi-user-img">
//           <SwiperComponent  testimonials={testimonials} />
//         </div>
//         <div className="user-saying">
//           {/* You can keep the existing content or modify it as needed */}
//           <div className="swiper-container testimonial">
//             <div className="swiper-wrapper">
//               {testimonials.map((testimonial, index) => (
//                 <div className="swiper-slide" key={index}>
//                   <div className="quote">
//                     <h2>{testimonial.company}</h2>
//                     <p>{testimonial.content}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className="swiper-pagination swiper-pagination-white"></div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TestimonialSection;

// import React from "react";

// const TestimonialSection = (props) => {
//   const { testimonials } = props;

//   return (
//     <section className="spacer">
//       <div className="testimonial-section">
//         <div className="testi-user-img">
//           <div className="swiper-container gallery-thumbs">
//             <div className="swiper-wrapper">
//               {testimonials.map((testimonial, index) => (
//                 <div className="swiper-slide" key={index}>
//                   <img
//                     className={`u${index + 1}`}
//                     src={testimonial.image}
//                     alt={testimonial.alt}
//                   />
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//         <div className="user-saying">
//           <div className="swiper-container testimonial">
//             <div className="swiper-wrapper">
//               {testimonials.map((testimonial, index) => (
//                 <div className="swiper-slide" key={index}>
//                   <div className="quote">
//                     <h2>{testimonial.company}</h2>
//                     <p>{testimonial.content}</p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className="swiper-pagination swiper-pagination-white"></div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TestimonialSection;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSection = (props) => {
  const { testimonials } = props;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 3000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div>
    <section className="spacer" >
      <div className="testimonial-section" style={{display:"flex", justifyContent:"center", alignItems:"center"  }}>
        <div className="testi-user-img">
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div className="swiper-slide" key={index}>
                <img
                  className={`u${index + 1}`}
                  src={testimonial.image}
                  alt={testimonial.alt}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="user-saying">
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div className="swiper-slide" key={index}>
                <div className="quote">
                  <h2>{testimonial.company}</h2>
                  <p>{testimonial.content}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

    </section>
    </div>
  );
};

export default TestimonialSection;