import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/vue.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGserver from "../../../../images/assets/server.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNGform from "../../../../images/assets/form.png";
import PNG23 from "../../../../images/assets/custom.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGap2 from "../../../../images/assets/ap2.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNGback from "../../../../images/assets/back.png";
import PNGecom from "../../../../images/assets/ecommerce.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const VueJs = () => {
    const tagLines = "Our VueJs Excellence";
    const title = "VueJS Development Services To Power World’s Most Innovative Companies";
    const description = `We are a team of experienced VueJS web developers who use the
                        framework to create user interfaces that draw in and keep people.
                        We use JavaScript libraries, component-composition caching, server-side
                        declarative rendering, and MVVM architecture to create single-page web apps, 
                        difficult to think Native, hybrid-Phonegap, or Cordova apps. We are a 
                        company that will not give up until our projects are strong enough
                        to compete. We accept tasks that produce results within 
                        the project's set timeframe and budget. `;
    const services = {
      title: " VueJs App & Development Services",
      tagLines: " VueJs Development With Professional Excellence",
      items: [
        {
          title: "VueJS Customization",
          imageSrc: PNG23,
          imageAlt:
            "VueJS Customization Services Nearby Ilford UK",
        },
        {
          title: "VueJS eCommerce Development",
          imageSrc: PNGecom,
          imageAlt: "VueJS eCommerce Development Agency In UK Ilford",
        },
        {
          title: "VueJS UI/UX Development",
          imageSrc: uiPNG,
          imageAlt: "VueJS UI/UX Development Company Near Ilford UK",
        },
        {
          title: " Single Page Application Developmen ",
          imageSrc: PNGform,
          imageAlt: "Single Page Application Development Service Provider In Ilford UK",
        },
        {
          title: "  VueJS maintenance and support service",
          imageSrc: PNG890,
          imageAlt:
            "VueJS maintenance and support service In Ilford UK",
        },
        {
          title: "VueJS consulting services",
          imageSrc: PNG58,
          imageAlt: "VueJS Consulting Services In Ilford UK",
        },
        {
          title: "Third-Party Integration with Vue.JS",
          imageSrc: PNG984,
          imageAlt: "Third-Party Integration with Vue.JS Framework In UK",
        },
        {
          title: " Real-Time Application Development ",
          imageSrc: PNG1221,
          imageAlt: "Real-Time Application Development Agency Near Ilford UK",
        },
      ],
      buttonText: "Inquire Now",
      buttonLink: "https://hastechsolutions.ca/contact.html",
    };
    const stats = [
      {
        image: PNG12,
        alt: "expertIcon",
        count: "15+",
        label: "VueJs Experts",
      },
      {
        image: PNG81,
        alt: "experienceIcon",
        count: "12+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "projectsIcon",
        count: "55+",
        label: "VueJs Projects Completed",
      },
      {
        image: PNG38,
        alt: "mobilePro",
        count: "300+",
        label: "Custom VueJs Projects",
      },
    ];
  
    const heading = "Raising The Bar For Vue Js Expertise";
  
    const testimonials = [
      {
        company: "Anyvision",
        content:
          "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
        image: MopePNG,
        alt: "Ionic App Development Comapny In Oakville Ilford UK",
      },
      {
        company: "Enterprise E-Commerce",
        content:
          "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
        image: PushPNG,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
      {
        company: "Glitty",
        content:
          "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
        image: PNG876,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
      {
        company: "Rogi",
        content:
          "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
        image: SinglePNG,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
    ];
  return (
    <>
    <Header />
    <HeroBannerSixNativeTech
      title="VueJS Development"
      description="Known for its easy integration and flexible single-page app development, we are early adopters and implementers of the top-notch JavaScript framework VueJS, delivering great Frontends. Our VueJS applications are scalable, setting a high benchmark for interactiveness and intuitiveness, We are now recognized as a leading VueJS Development Company - globally. We don’t just build beautiful front ends. We build winning business solutions."
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={designPNG}
      imageAlt="Best VueJs Web Development Company Nearby Ilford UK"
    />
    <ContainerMiniLeftNativeTech
      tagLines={tagLines}
      title={title}
      description={description}
    />
    {/* <ContainerNativeTech/> */}
    <ContainerNativeTech services={services} />
    {/* <ServiceStateNativeTech/> */}
    <ServiceStateNativeTech stats={stats} heading={heading} />
    {/* <TestimonialNativeTech/> */}
    <TestimonialSection testimonials={testimonials} />;
    <Footer />
  </>
  )
}

export default VueJs