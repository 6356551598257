import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../../images/assets/xamarin.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNG58 from "../../../../../images/assets/58.png";
import PNG890 from "../../../../../images/assets/890.png";
import PNG23 from "../../../../../images/assets/23.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";
import PNGform from "../../../../../images/assets/form.png";
import PNGprogress from "../../../../../images/assets/progress.png";
import PNGandroid from "../../../../../images/assets/android.png";
import PNGiOS from "../../../../../images/assets/ios.png";
import PNGcross from "../../../../../images/assets/cross.png";

import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";


const Xamarin = () => {
    const tagLines = "Our Xamarin App Development Excellence";
  const title = "Shape The Landscape Of Tomorrow With Native-Like Xamarin Apps";
  const description =    `Our strong foot in cross-platform app development when brought
                         together with award-winning skills in developing Xamarin native
                         apps helps us to gain more from the framework than others. 
                         Xamarin app development companies. When this proficiency in
                         building C# powered native apps is coupled with our talented 
                         team of Xamarin app developers, it gives you the power to 
                         work beyond boundaries and deliver an outstanding application.
                          We at Hastech develop, test, and launch a cross-platform app 
                          that radiates style and authenticity. `;
                          
  const services = {
    title: " Xamarin App Development Services",
    tagLines: " Xamarin Development With Professional Excellence",
    items: [
      {
        title: "Xamarin Forms App Development",
        imageSrc: PNGform,
        imageAlt:
          "Best Xamarin Forms App Development Company Nearby Ilford UK",
      },
      {
        title: "Xamarin Cross-Platform Apps Development",
        imageSrc: PNGcross,
        imageAlt: "Xamarin Cross-Platform Apps Development Freelancer Nearby Ilford UK",
      },
      {
        title: "Application Shell Architecture",
        imageSrc: PNGprogress,
        imageAlt: "Application Shell Architecture Maker Nearby Ilford UK",
      },
      {
        title: " Xamarin App Support & Maintenance ",
        imageSrc: PNG58,
        imageAlt: "Xamarin App Support & Maintenance Services Provider Nearby Ilford UK",
      },
      {
        title: " Xamarin Migration & Upgradation Services",
        imageSrc: PNG890,
        imageAlt:
          "Xamarin Migration and Upgradation Services In Ilford UK",
      },
      {
        title: "Xamarin Android App Development",
        imageSrc: PNGandroid,
        imageAlt: "Xamarin Android App Development Agency Nearby Ilford UK",
      },
      {
        title: "Xamarin iOS App Development",
        imageSrc: PNGiOS,
        imageAlt: "Best Xamarin iOS App Development Services In Ilford UK",
      },
      {
        title: " Xamarin App Testing Services ",
        imageSrc: PNG23,
        imageAlt: "Xamarin App Testing Services Nearby Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: " Xamarin Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Xamarin Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Xamarin App Projects",
    },
  ];

  const heading = "Raising The Bar For Xamarin App Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
    <Header />
      <HeroBannerSixNativeTech
        title="Xamarin App"
        description="Cross-platform app development is our forte. We create near-native applications in iOS and Android with Xamarin to leverage full-platform functionality and amazing native controls provided by it. Craft your native app like story with Xamarin developers at Hastech to keep pace with the current market, quick development, and minimum costs."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Xamarin App Development Service Provider In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  )
}

export default Xamarin