import React from 'react'
import {NavLink} from "react-router-dom" 
import Header from '../../../../Common/Header'
import Footer from '../../../../Common/Footer'
import HeroBannerSixNativeTech from '../components/HeroBannerSixNativeTech'
import ContainerMiniLeftNativeTech from '../components/ContainerMiniLeftNativeTech'
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../../images/assets/design.png";
import ContainerNativeTech from "../components/ContainerNativeTech"
import softPNG from '../../../../../images/assets/soft.png';
import extPNG from '../../../../../images/assets/ext.png';
import uiPNG from '../../../../../images/assets/ui.png';
import PNG58 from '../../../../../images/assets/58.png';
import PNG890 from '../../../../../images/assets/890.png';
import PNG984 from '../../../../../images/assets/984.png';
import PNG78 from '../../../../../images/assets/78.png';
import PNG1221 from '../../../../../images/assets/1221.png';
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";
import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";

const IphonePage = () => {
  const tagLines = "Our iPhone Excellence";
  const title = "A Potent iPhone App Development Company Delivering Polished App";
  const description = `Looking for an app that is as good as the Apple app? We have the perfect app for you! 
                       Our long-standing design and functionality standards make our apps stand out, 
                       and today 55% of U.S residents use iPhones. So if you're looking for an innovative 
                       business app, we're your best bet. With our help, you can define and enable your 
                       idea for the next successful iOS application.
                       We work with clients from defining and enabling ideation to successful planning, 
                       from testing to monitoring, and from ensuring current market relevance to marketing.`;
  const services = {
                          title: 'iPhone App Development Services',
                          tagLines: ' iPhone Development With Professional Excellence',
                          items: [
                            {
                              title: 'Custom iPhone App Development',
                              imageSrc: softPNG,
                              imageAlt: 'Best iPhone Application Developers In Oakville, Ilford UK'
                            },
                            {
                              title: 'Enterprise iOS Solutions',
                              imageSrc: extPNG,
                              imageAlt: 'Enterprise iOS App Developer In Oakville Ilford'
                            },
                            {
                              title: 'iPhone app UI/UX Design',
                              imageSrc: uiPNG,
                              imageAlt: 'iPhone app UI/UX Design Developer In Ilford'
                            },
                            {
                              title: 'iPhone App Consultation',
                              imageSrc: PNG58,
                              imageAlt: 'iPhone App Consultation Agency In Ilford UK'
                            },
                            {
                              title: 'iPhone app Support, Optimization, and Maintenance',
                              imageSrc: PNG890,
                              imageAlt: 'iPhone app Support, Optimization, and Maintenance Provider In Oakville, Ilford UK'
                            },
                            {
                              title: 'iPhone Widget/Extension Development',
                              imageSrc: PNG78,
                              imageAlt: 'iPhone Widget/Extension Development Freelancer In Ilford UK'
                            },
                            {
                              title: 'iPhone App Integration',
                              imageSrc: PNG984,
                              imageAlt: 'iPhone App Integration Services Provider In Oakville, UK'
                            },
                            {
                              title: 'iPhone App Prototyping',
                              imageSrc: PNG1221,
                              imageAlt: 'iPhone App Prototyping Freelancer In Oakville Ilford'
                            }
                          ],
                          buttonText: 'Inquire Now',
                          buttonLink: 'https://hastechsolutions.ca/contact.html'
                      };                     
  const stats = [
                        {
                          image: PNG12,
                          alt: "Iphone App Developer Near Me Oakville, Ilford, UK",
                          count: "15+",
                          label: "iPhone Experts",
                        },
                        {
                          image: PNG81,
                          alt: "Experience Iphone App Developer Near Me Ilford",
                          count: "10+",
                          label: "Years Of Experience",
                        },
                        {
                          image: PNG36,
                          alt: "Freelance Projects Iphone App Developer Nearby Ilford",
                          count: "50+",
                          label: "iPhone Projects Completed",
                        },
                        {
                          image: PNG38,
                          alt: "Best Quality iPhone Application Developers In Oakville, Ilford UK",
                          count: "300+",
                          label: "Custom iphone App Projects",
                        },
                ];
                    
       const heading = "Raising The Bar For iPhone Expertise";

  const testimonials = [
        {
          company: "Anyvision",
          content:
            "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
          image: MopePNG,
          alt: "Best Quality iPhone Application Developers In Oakville, Ilford UK",
        },
        {
          company: "Enterprise E-Commerce",
          content:
            "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
          image: PushPNG,
          alt: "Best Quality iPhone Application Freelancer In Oakville, Ilford UK",
        },
        {
          company: "Glitty",
          content:
            "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
          image: PNG876,
          alt: "Best Quality iPhone Application Agency In Oakville, Ilford UK",
        },
        {
          company: "Rogi",
          content:
            "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
          image: SinglePNG,
          alt: "Best Quality iPhone Application Company In Oakville, Ilford UK",
        },
                     ];
         
  
  return (
    <>
    <Header/>
    <HeroBannerSixNativeTech
        title="iPhone App"
        description="HASTech stands high as an iPhone Application Developer in Ilford UK taking bold steps from pixel-perfect designs to intuitive backends, to fill each dimension of the modern iOS app development realm. For the quality that Apple devices have, our Best iPhone Application Developers in Ilford UK are bound to set the bar high with a product that is bold, intuitive, timely, and innovative."
        buttonText="Inquire Now"
         buttonLink={<NavLink to='/contact' className="d-flex align-items-center ios-button gr-bg-one hover-reverse-gr-bg-one order-sm-first">
    <img
      // src={touchicom}
      alt="Web Development Comapny In UK"
      className="icon"
    />
    <div>
      <strong>Get in Touch</strong>
    </div>
  </NavLink>}
        imageSrc={designPNG}
        imageAlt="Best iPhone Application Developers In Oakville, Ilford UK"
      />
    <ContainerMiniLeftNativeTech
    tagLines={tagLines}
    title={title}
    description={description}
    />
    {/* <ContainerNativeTech/> */}
    <ContainerNativeTech services={services} />
    {/* <ServiceStateNativeTech/> */}
    <ServiceStateNativeTech stats={stats} heading={heading} />
    {/* <TestimonialNativeTech/> */}
    <TestimonialSection testimonials={testimonials} />;
    <Footer/>
    </>
  )
}

export default IphonePage