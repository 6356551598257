import React from 'react'
import mad from "../../images/assets/mad.png"
import rd from "../../images/assets/rd.png"
import dp from "../../images/assets/dp.png"
import pth from "../../images/assets/pth.png"
import { NavLink } from 'react-router-dom'
const DigitalMarketing = () => {
  return (
    <>
    
			<div className="Digital Marketing_row">
				<div className="container">
					<div className="tech-layer"></div>
					<div className="row">

						<div className="col-md-4">
							<div className="cmr-tech-xicon uiux-tech">
								<div className="spriteOne spt-uiux-tech postion-number-six"></div>
							</div>
							<div>
								<h3 className="cmr-title  ">
								<div> <b>Digital</b> &nbsp;<br/>Marketing</div></h3>
							</div>
						</div>
						
						<div className="col-md-8">
							<div className="cmr-techstop">
								<div className="row justify-content-start techstop-list">
									<div className="col techstop-item-1">
										<NavLink to='/searchengineoptimization' >
											<img src={mad} alt="Mobile App Design Services In Oakville UK" height="69" width="56"/>
											<h5 className="mobile1">Search Engine <br/>Optimization</h5>
										</NavLink>
									</div>
									<div className="col techstop-item-1">
										<NavLink to = "/socialmediamarketing">
											<img src={rd} alt="Responsive Designs Services Nearby Oakville UK" height="69" width="56"/>
											<h5 className="mobile">Social Media <br/>Marketing</h5>
										</NavLink>
									</div>
									<div className="col techstop-item-1">
										<NavLink to='/socialmediaoptimizing'>
											<img src={dp} alt="Best Design Prototyping Services Nearby Oakville UK" height="69" width="56"/>
											<h5 className="mobile">Social Media <br/>Optiomization</h5>
										</NavLink>
									</div>
									<div className="col techstop-item-1">
										<NavLink to='/payperclick'>
											<img src={pth} alt="Best PSD To HTML Services Nearby Oakville UK" height="69" width="56"/>
											<h5  className="mobile2">Pay-Per-Click(PPC)</h5>
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            
    </>
  )
}

export default DigitalMarketing