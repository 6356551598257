import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../../images/assets/flutter.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../../images/assets/soft.png";
import extPNG from "../../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
// import PNG58 from "../../../../images/assets/58.png";
// import PNG890 from "../../../../images/assets/890.png";
// import PNG984 from "../../../../images/assets/984.png";
// import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";
import PNG239 from "../../../../../images/assets/239.png";
import PNGapi from "../../../../../images/assets/api.png";
import PNGand from "../../../../../images/assets/and.png";
import PNGiOS from "../../../../../images/assets/ios.png";
import PNGcross from "../../../../../images/assets/cross.png";

import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";

const FlutterPage = () => {
  const tagLines = "Our Flutter Excellence";
  const title =
    "A Potent Flutter App Development Company Delivering Polished App";
  const description = `Irrelevant to the numbers, in the age of appeal-driven apps, 
  nothing can substitute Android. The long-time established design and functionality
   standards of Flutter apps, still hold the fort. No wonder, today 40% of U.S residents
    use Android Phones, so, not just having a business app becomes a priority but having an interactive, 
    innovative app is required to stand out. We are here to hear your idea for the next successful
     Flutter application. We work with clients to define and enable ideation, from testing to monitoring, 
     from ensuring current market relevance to marketing.`;
  const services = {
    title: " Flutter App Development Services",
    tagLines: " Flutter App Development With Professional Excellence",
    items: [
      {
        title: "Custom Flutter application Development",
        imageSrc: softPNG,
        imageAlt: "Custom Flutter Application Development Agency In Ilford UK",
      },
      {
        title: "Backend Development",
        imageSrc: PNG239,
        imageAlt: "Flutter Backend Development Services Nearby Ilford UK",
      },
      {
        title: "Flutter API Development",
        imageSrc: PNGapi,
        imageAlt: "Best Flutter API Development Agency Nearby Ilford UK",
      },
      {
        title: " Flutter development for Android platform ",
        imageSrc: PNGand,
        imageAlt:
          "Top Company Flutter Development For Android Platform In Ilford UK",
      },
      {
        title: "  Flutter development for iOS application",
        imageSrc: PNGiOS,
        imageAlt: "Flutter Development Agency For iOS Application In Ilford UK",
      },
      {
        title: "Flutter cross-platform development services",
        imageSrc: PNGcross,
        imageAlt:
          "Flutter Cross-Platform Development Services Nearby Ilford UK",
      },
      {
        title: "UI/UX Design",
        imageSrc: uiPNG,
        imageAlt: "Flutter UI/UX Designer Freelancer Nearby Ilford UK",
      },
      {
        title: "  Flutter App Development ",
        imageSrc: PNG1221,
        imageAlt: "Flutter Front-end Development Services Nearby Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Flutter Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Flutter Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Flutter App Projects",
    },
  ];

  const heading = "Raising The Bar For Flutter Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Flutter App"
        description="We are locked and loaded with the art to envision the most bizarre ideas with real-time abilities of Flutter to be customized and transformed into an asset. We are unicorns when it comes to cross-platform development abilities which stand us apart to deliver native experiences that never go out of style."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default FlutterPage;
