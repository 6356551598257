import React, { useState } from "react";
import logo from "../../images/logo/logo.png";
import img165 from "../../images/shape/165.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <footer className="theme-footer-six">
        <div className="inner-container">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-4">
                <div className="logo">
                  <a href="https://hastechsolutions.co.uk/">
                    <img src={logo} alt="Hastech Solutions Logo" />
                  </a>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="title">Follow Us On Social Media</div>

                <ul className="d-flex justify-content-center social-icon">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=100094362262859"
                      target="_blank">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.instagram.com/hastechsolutionsuk/"
                      target="_blank">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="https://www.linkedin.com/in/hastech-solutions-72680a255/"
                      target="_blank">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li> */}

                  <li>
                    <a href="https://wa.me/447774821031" target="_blank">
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>

                <div className="title mt-4">Address</div>

                <h5 className="text-center">
                      1-5 The Parade Monarch Way, IIford, 1G2 7HT
                </h5>

                <lottie-player
                  src="https://assets2.lottiefiles.com/packages/lf20_oURJje.json"
                  background="transparent"
                  speed="1"
                  loop=""
                  autoplay=""
                ></lottie-player>
              </div>

              <div className="col-lg-4">
                <div className="title">We are always happy to help.</div>

                <div className="text-center">
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:info@hastechsolutions.co.uk" className="email">
                      info@hastechsolutions.co.uk
                  </a>
                </div>

                {/* <div className="text-center">
                  <i className="fa fa-phone-square"></i>
                  <a href="tel:+44 7774-821031" className="email">
                      +44 7774-821031
                  </a>
                </div> */}
              </div>
            </div>

            <p className="copyright">
              Copyright @2022{" "}
              <a href="https://hastechsolutions.co.uk/" target="_blank">
                HAStech Solutions
              </a>
            </p>
          </div>

          <img
            src={img165}
            alt="Best Software Development, Web Development And Digital Marketing Company In Ilford,UK"
            className="shapes shape-one"
          />
        </div>
        <button
        className="scroll-top"
        style={{ background: "#ce3f56" }}
        aria-label="Scroll to top"
        onClick={scrollToTop}
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </button>
      </footer>
    </>
  );
};

export default Footer;
