import React from 'react';

const ContainerNativeTech = ({ services }) => {
  return (
    <div>
      <div className="insideedge mt-5 mobile-custom-spacing-top">
        <div className="container">
          <div className="cmr-reflectors">SERVICES</div>

          <div className="row mb-5 align-items-center mobile-custom-spacing-bottom-4">
            <div className="col-md-5">
              <div className="sec-title">
                <p className="text">
                  <span>We Provide</span>
                </p>

                <h2 className="services-heading">
                  {services.title}
                </h2>
              </div>
            </div>

            <div className="col-md-7">
              <h4 className="right-tag-lines">
                <span>Next-Gen</span>
                {services.tagLines}
              </h4>
            </div>
          </div>

          <div className="row justify-content-center">
            {services.items.map((item, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                <div className="ser-plate">
                  <img
                    src={item.imageSrc}
                    alt={item.imageAlt}
                    style={{ width: '100px', height: '100px' }}
                  />
                  <div className="ser-title">{item.title}</div>
                </div>
              </div>
            ))}
          </div>
{/* 
          <div className="mt-4">
            <button className="cmr-solid-btn get-center hvr-shutter-out-horizontal">
              <i className="icon-arrow-right">
                <a
                  className="button__link"
                  href={services.buttonLink}
                  data-toggle="modal"
                  data-target="#contactModal"
                >
                  {services.buttonText}
                </a>
              </i>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContainerNativeTech;
