import React from 'react'

const ServiceStateNativeTech  = (props) => {
    const { stats, heading } = props;
  return (
    <div className="anmtn-element in-view speak">
      <div className="service-stats animated-cuts-left">
        <div className="container">
          <div className="sec-title text-center">
            <p>
              <span>Numbers That Speak Our Power</span>
            </p>
            <h3>{heading}</h3> {/* Render the heading dynamically */}
          </div>

          <div className="left-cq"></div>
          <div className="right-cq"></div>
          <div className="cmr-reflectors">STATS</div>

          <div className="row mt-5 mobile-custom-spacing-top">
            {stats.map((stat, index) => (
              <div className="col-sm-6 col-lg-3 col-6" key={index}>
                <div className="expertise">
                  <div className="stat-icon">
                    <img
                      src={stat.image}
                      alt={stat.alt}
                      width="100"
                      height="100"
                    />
                  </div>
                </div>

                <div className="stat-details">
                  <h4 style={{ textAlign: "center" }}>{stat.count}</h4>
                  <p style={{ textAlign: "center" }}>{stat.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceStateNativeTech