import React from 'react'
import Header from '../Common/Header'
import FancyShortBanner from '../Home/FancyShortBanner'
import Footer from '../Common/Footer'
import ContactHeroBanner from './ContactHeroBanner'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <>
        <Header/>
        <ContactHeroBanner/>
        <ContactForm/>
        <FancyShortBanner/>
        <Footer/>
    
    </>
  )
}

export default Contact