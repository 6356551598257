import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import SYM23 from "../../../../images/assets/sym23.png";
import PNGsymfony from "../../../../images/assets/symfony.png";
import PNGc23 from "../../../../images/assets/c23.png";
import pluginPNG from "../../../../images/assets/plugin.png";
import EcomPNG from "../../../../images/assets/ecommerce.png";
import ZendPNG from "../../../../images/assets/zend.png";
import umsPNG from "../../../../images/assets/ums.png";
import SqlPNG from "../../../../images/assets/sql23.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG98 from "../../../../images/assets/98.png";
import Cms34 from "../../../../images/assets/cms34.png";
import PNGmb34 from "../../../../images/assets/mb34.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import CodegniterPNG from "../../../../images/assets/codegniter.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import intPNG from "../../../../images/assets/integration.png";
import extPNG from "../../../../images/assets/ext.png";

const SymfonyPage = () => {
  const tagLines = "Our symfony Excellence";
  const title = "Scale Quickly With Our Symfony PHP Powerhouse Development";
  const description = `Symfony is a versatile programming language that can be used to build websites and applications 
                      all over the world. Symfony development services are widely practiced and provide
                      well-defined solutions that create apps that work smart and hard. A complete roadmap
                        is designed rationally with few top-skilled Symfony developers to enable you a 
                        Hastech power that gets you to top.`;
  const services = {
    title: "Symfony  Development Services",
    tagLines: " Symfony Development With Professional Excellence",
    items: [
      {
        title: "Symfony Rapid Application",
        imageSrc: softPNG,
        imageAlt: "Symfony Rapid Application",
      },
      {
        title: "Symfony Module Development",
        imageSrc: SYM23,
        imageAlt: "Symfony Module Development",
      },
      {
        title: "Integration Solutions",
        imageSrc: intPNG,
        imageAlt: "Integration Solutions",
      },
      {
        title: "Enterprise Apps Development",
        imageSrc: extPNG,
        imageAlt: "Enterprise Apps Development",
      },
      {
        title: "Migration and Upgradation Services",
        imageSrc: PNG890,
        imageAlt: "Migration and Upgradation Services",
      },
      {
        title: "Symfony Plugin Development",
        imageSrc: pluginPNG,
        imageAlt: "Symfony Plugin Development",
      },
      {
        title: "Symfony API Development",
        imageSrc: PNG984,
        imageAlt: "Symfony API Development",
      },
      {
        title: "Symfony eCommerce Development",
        imageSrc: PNG1221,
        imageAlt: "Symfony eCommerce Development",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Symfony Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Symfony Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Symfony Projects",
    },
  ];

  const heading = "Raising The Bar For  Sympfony Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Symfony App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Symfony App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Symfony App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Symfony App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Symfony"
        description="From startups to enterprise, right Symfony development services can be a game changer for your business challenges. At Hastech, with the power of Symfony we automate business processes quickly, diminish inapt aspects and unlock new possibilities to grow the bottom line."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={PNGsymfony}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default SymfonyPage;
