import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components//ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
// import designPNG from "../../../../images/assets/pwa.png";
import designPNG from "../../../../../images/assets/pwa.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../../images/assets/soft.png";
import extPNG from "../../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
import PNG58 from "../../../../../images/assets/58.png";
import PNG890 from "../../../../../images/assets/890.png";
import PNG984 from "../../../../../images/assets/984.png";
import PNG78 from "../../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";
import PNGplugin from "../../../../../images/assets/plugin.png";
import PNGprogress from "../../../../../images/assets/progress.png";
import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";

const Pwa = () => {
  const tagLines = "Our Progressive Web App Excellence";
  const title =
    "A Potent Progressive Web App Development Company Delivering Polished App";
  const description = `We bring technologies like Progressive Web App to you ensuring 
                      ongoing growth and wider outreach for your business. We realize your business 
                      deserves a holistic approach and so our cross-platform solutions are built to 
                      deliver user experiences that meet the standards of Progressive Web App Development
                      and classics of the web.`;

  const services = {
    title: " Progressive Web App Development Services",
    tagLines: " Progressive Web App Development With Professional Excellence",
    items: [
      {
        title: "Progressive Web Application Development",
        imageSrc: softPNG,
        imageAlt:
          "Progressive Web Application Development Agency Nearby Ilford UK",
      },
      {
        title: "Progressive Plugin Development",
        imageSrc: PNGplugin,
        imageAlt: "Progressive Plugin Development Freelancer In Oakville UK",
      },
      {
        title: "Progressive Web App Design",
        imageSrc: uiPNG,
        imageAlt: "Top Progressive Web App Design Services In Ilford UK",
      },
      {
        title: "Progressive App Shell Architecture ",
        imageSrc: PNGprogress,
        imageAlt: "Progressive App Shell Architecture Services In Oakville UK",
      },
      {
        title: "  Quality Responsive Design",
        imageSrc: PNG984,
        imageAlt: "Quality Responsive Design Services In Oakville UK",
      },
      {
        title: " Application Shell Architecture",
        imageSrc: PNG1221,
        imageAlt: "Top Application Shell Architecture Nearby Ilford UK",
      },
      {
        title: "Progressive App Integration",
        imageSrc: PNG984,
        imageAlt: "Best Progressive App Integration Developer Nearby Ilford UK",
      },
      {
        title: " Progressive App QA & Testing ",
        imageSrc: PNG1221,
        imageAlt: "Top Progressive QA and Testing Agency In Oakville Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Progressive Web App Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "PWA Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom PWA Projects",
    },
  ];

  const heading = "Raising The Bar For Progressive Web App Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Progressive Web App Development With Professional Excellence"
        description="We bring technologies like Progressive Web App to you ensuring ongoing growth and wider outreach for your business. We realize your business deserves a holistic approach and so our cross-platform solutions are built to deliver user experiences that meet the standards of mobile app and classics of the web."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Web App Development And Design Agency In Ilford UK."
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default Pwa;
