import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import ResponsivePNG from "../../../../images/assets/responsive.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import Design43 from "../../../../images/assets/design43.png";
import PNG999 from '../../../../images/assets/999.png'

const PsdToHtmlPage = () => {
  const tagLines = "Our PSD To HTML Excellence";
  const title =
    "PSD To HTML Conversion Services For Designing An Experience";
  const description = `Your website is only as good as it looks and is enjoyable to browse. Your website uses high-quality HTML coding to create a professional look and feel. We take a lean approach to web development, striving to fully understand your objectives and barriers so that we can provide you with the
   best possible design solution. W3C approved and inspected, PSD to HTML is the best way to go for web development.`;
  const services = {
    title: "PSD to HTML Development Services",
    tagLines: " PSD to HTML Development With Professional Excellence",
    items: [
      {
        title: "Latest HTML Web Standards",
        imageSrc: softPNG,
        imageAlt: "Latest HTML Web Standards",
      },
      {
        title: "PSD to XHTML/CSS2F",
        imageSrc: extPNG,
        imageAlt: "PSD to XHTML/CSS2F",
      },
      {
        title: "PSD to HTML Designs",
        imageSrc: uiPNG,
        imageAlt: "PSD to HTML Designs",
      },
      {
        title: "PSD to Bootstrap conversion",
        imageSrc: PNG58,
        imageAlt: "PSD to Bootstrap conversion",
      },
      {
        title: "PSD to Wordpress",
        imageSrc: PNG890,
        imageAlt: "PSD to Wordpress",
      },
      {
        title: "PSD Slicing / HTML Conversion",
        imageSrc: PNG78,
        imageAlt: "PSD Slicing / HTML Conversion",
      },
      {
        title: "PSD to HTML Integration",
        imageSrc: PNG984,
        imageAlt: "PSD to HTML Integration",
      },
      {
        title: "PSD to HTML Projects",
        imageSrc: PNG1221,
        imageAlt: "PSD to HTML Projects",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "PSD to HTML Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "PSD to HTML Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom PSD to HTML Projects",
    },
  ];

  const heading = "Raising The Bar For PSD to HTML Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="PSD to HTML"
        description="Probably you have found a UI that compliments your user’s instincts and reinforces a potential connection with brand value. We have the best team of frontend development team who implement
         their best PSD to HTML practices to transform this ideal UI into cross-browser compatible W3C, HTML5/CSS3 standard file."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={PNG999}
        imageAlt="Best & Quality Design Prototyping Development Services In UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default PsdToHtmlPage;
