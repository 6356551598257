import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/rn1.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGserver from "../../../../images/assets/server.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNGform from "../../../../images/assets/form.png";
import PNGsoft from "../../../../images/assets/soft.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGap2 from "../../../../images/assets/ap2.png";
import PNG28 from "../../../../images/assets/28.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG23 from "../../../../images/assets/23.png";
import PNGplugin from "../../../../images/assets/plugin.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const ReactPage = () => {

  const tagLines = "Our ReactJs Excellence";
  const title = "Scale High With Resilient, Real-Time & Robust Application";
  const description = `You need a platform that is as cutting-edge as React and is supported

                        by leading companies like Facebook, Instagram, and Yahoo. If you're looking

                        for an online application platform that is top-of-the-line, React is the perfect

                        choice. In an era where most websites and apps are unremarkable, we at the best 

                        ReactJs Development Company take you to a place where you are the best of everyone. 

                        Rich internet apps. Best user interface. 

                        Quick Real-time updates. Swift loading High performance. That’s our promised ReactJS development metaverse. ReactJS gives you a performance advantage when building high-data online applications. 

                        This is due to its quick and reliable react-js code that is watermark-efficient.`;

  const services = {
    title: " ReactJs App Development Services",
    tagLines: " ReactJs Development With Professional Excellence",
    items: [
      {
        title: "Custom iPhone App Development",
        imageSrc: PNGsoft,
        imageAlt:
          "Custom iPhone App Development",
      },
      {
        title: "React JS QA and Testing",
        imageSrc: PNG23,
        imageAlt: "Enterprise iOS Solutions Provider In Onatrio UK",
      },
      {
        title: "React UI/UX Development",
        imageSrc: uiPNG,
        imageAlt: "React UI/UX Development Company In UK",
      },
      {
        title: "ReactJS Front-End Development ",
        imageSrc: PNG58,
        imageAlt: "ReactJS Front-End Development Service Provider In Ilford UK",
      },
      {
        title: "  React JS Maintenance and Support",
        imageSrc: PNG890,
        imageAlt:
          "React JS Maintenance and Support Service Provider In Ilford UK",
      },
      {
        title: "React Native App Development",
        imageSrc: PNG28,
        imageAlt: "React Native App Development In Onatrio UK",
      },
      {
        title: "Custom React.JS Development",
        imageSrc: PNG984,
        imageAlt: "Custom React.JS Development Developer Near Ilford UK",
      },
      {
        title: " ReactJS Plugins & Components Development ",
        imageSrc: PNGplugin,
        imageAlt: "ReactJS Plugins & Components Development Services In Onatrio UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "ReactjS Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "ReactjS Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom ReactJs Projects",
    },
  ];

  const heading = "Raising The Bar For ReactjS Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
    <Header />
    <HeroBannerSixNativeTech
      title="React JS Development"
      description="Appealing in speed and flawless in function, that’s the core of our ReactJS Development Services. Our ReactJS developers leverage the component-based architecture of React to translate the business processes into lightning-fast and rich real-time internet applications Being the prominent ReactJS Development Company, we contrive a room for future development with highly engaging and interactive web apps."
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={designPNG}
      imageAlt="ReactJs App Development Agency Nearby Ilford UK"
    />
    <ContainerMiniLeftNativeTech
      tagLines={tagLines}
      title={title}
      description={description}
    />
    {/* <ContainerNativeTech/> */}
    <ContainerNativeTech services={services} />
    {/* <ServiceStateNativeTech/> */}
    <ServiceStateNativeTech stats={stats} heading={heading} />
    {/* <TestimonialNativeTech/> */}
    <TestimonialSection testimonials={testimonials} />;
    <Footer />
  </>
  )
}

export default ReactPage