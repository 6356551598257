import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import AboutHeroBanner from "./AboutHeroBanner";
import AboutContainerFluid from "./AboutContainerFluid";
import AboutSolveSection from "./AboutSolveSection";
import AboutThings from "./AboutThings";
import AboutMisVis from "./AboutMisVis";
const About = () => {
  return (
    <>
      <>
        <Header />
        <AboutHeroBanner />
        <AboutContainerFluid />
        <AboutSolveSection />
        <AboutThings />
        <AboutMisVis />
        <Footer />
      </>
    </>
  );
};

export default About;
