import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/meteor.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGserver from "../../../../images/assets/server.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNGform from "../../../../images/assets/form.png";
import PNGsoft from "../../../../images/assets/soft.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGap2 from "../../../../images/assets/ap2.png";
import PNG28 from "../../../../images/assets/28.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG23 from "../../../../images/assets/23.png";
import PNGcross from "../../../../images/assets/23.png";
import PNGplugin from "../../../../images/assets/plugin.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import PNGecom from "../../../../images/assets/ecommerce.png";

const MeteorJs = () => {
  const tagLines = "Our MeteorJs Excellence";
  const title = "Scale High With Resilient, Real-Time & Robust Application";
  const description = `You need a platform that is as cutting-edge as MeteorJs and is supported by
                      leading companies like Facebook, Instagram, and Yahoo. If you're looking for an online
                        application platform that is top-of-the-line, MeteorJs is the perfect choice. 
                        In an era where most websites and apps are unremarkable, we at the best MeteorJs
                        Development Company take you to a place where you are the best of everyone. 
                        Rich internet apps. Best user interface. Quick Real-time updates. Swift loading 
                        High performance. That’s our promised MeteorJs development metaverse. 
                        MeteorJs gives you a performance advantage when building high-data online applications.
                          This is due to its quick and reliable MeteorJs code that is watermark-efficient.`;

  const services = {
    title: " Meteor App Development Services",
    tagLines: " Meteor Development With Professional Excellence",
    items: [
      {
        title: "Test Driven Development",
        imageSrc: PNG23,
        imageAlt: "Cross-Platform App Development Services In Ilford UK",
      },
      {
        title: "MeteorJS UI/UX Development",
        imageSrc: uiPNG,
        imageAlt: "MeteorJS UI/UX Development Agency Near Ilford UK",
      },
      {
        title: " MeteorJs Plug-in Development",
        imageSrc: PNGplugin,
        imageAlt:
          "MeteorJs Plug-in Development Services Provider In Ilford UK",
      },
      {
        title: "MeteorJS Support And Maintenance ",
        imageSrc: PNG890,
        imageAlt: "MeteorJS Support And Maintenance Agency In Ilford UK",
      },
      {
        title: "  MeteorJS E-commerce Development",
        imageSrc: PNGecom,
        imageAlt: "MeteorJS E-commerce Development Company In Ilford UK",
      },
      {
        title: "MeteorJS Full Stack Development",
        imageSrc: PNG984,
        imageAlt:
          "MeteorJS Full Stack Development Freelancer In Ilford UK",
      },
      {
        title: "Web Services And API Development",
        imageSrc: PNG1221,
        imageAlt:
          "Web Services And API Development Service Provider In Ilford UK",
      },
      {
        title: " MeteorJS Plugins & Components Development ",
        imageSrc: PNGplugin,
        imageAlt:
          "MeteorJS Plugins & Components Development Services In Onatrio UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "MeteorJs Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "MeteorJs Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom MeteorJs  Projects",
    },
  ];

  const heading = "Raising The Bar For MeteorJS Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="MeteorJS Development"
        description="An application should always be the worth of your outstanding idea. With the platform MeteorJS that acts as a bridge between web and mobile platforms, we push our capabilities to bring you a robust, error-free, and rapid application that speaks about your unique idea."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="MeteorJS App Development Agency Nearby Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default MeteorJs;
