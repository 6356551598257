import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../../images/assets/nat.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../../images/assets/soft.png";
import extPNG from "../../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
import PNG58 from "../../../../../images/assets/58.png";
import PNG890 from "../../../../../images/assets/890.png";
import PNG984 from "../../../../../images/assets/984.png";
import PNG78 from "../../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";

import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";

const ReactNative = () => {
    const tagLines = "React Native Development";
    const title = "A Potent React Native App Development Company Delivering Polished App";
    const description = `You need a platform that is as cutting-edge as React and is supported by leading companies like Facebook, Instagram, and Yahoo. If you're looking for an online application platform that is top-of-the-line, React Native is the perfect choice. In an era where most websites and apps are unremarkable, we at the best React Native Development Company take you to a place where you are the best of everyone. Rich internet apps. Best user interface. Quick Real-time updates. Swift loading High performance. That’s our promised React Native development metaverse. React Native gives you a performance advantage when building high-data online applications. This is due to its quick and reliable react-native code that is watermark-efficient.`;
    const services = {
      title: " React Native App Development Services",
      tagLines: " React Native Development With Professional Excellence",
      items: [
        {
          title: "React Native App Development",
          imageSrc: softPNG,
          imageAlt:
            "Best React Native Development Services Provider In Ilford UK",
        },
        {
          title: "React Native Code Audit",
          imageSrc: extPNG,
          imageAlt: "React Native Code Audit Freelancer",
        },
        {
          title: "React Native UI/UX Design",
          imageSrc: uiPNG,
          imageAlt: "Best React Native App UI/UX Design Freelancer In Ilford UK",
        },
        {
          title: "React Native App Consultation ",
          imageSrc: PNG58,
          imageAlt: "React Native App Consultationt Services Nearby Ilford UK",
        },
        {
          title: " React Native Maintenance & Support",
          imageSrc: PNG890,
          imageAlt:
            "React Native Maintenance & Support Services Nearby Ilford UK",
        },
        {
          title: "React Native Widget Development",
          imageSrc: PNG78,
          imageAlt: "React Native Widget Development Services Nearby Ilford UK",
        },
        {
          title: "React Native App Integration",
          imageSrc: PNG984,
          imageAlt: "React Native App Integration Services Nearby Ilford UK",
        },
        {
          title: " React Native App Re-engineering ",
          imageSrc: PNG1221,
          imageAlt: "React Native App Re-engineering Tester In Ilford UK",
        },
      ],
      buttonText: "Inquire Now",
      buttonLink: "https://hastechsolutions.ca/contact.html",
    };
    const stats = [
      {
        image: PNG12,
        alt: "expertIcon",
        count: "15+",
        label: "React Native Experts",
      },
      {
        image: PNG81,
        alt: "experienceIcon",
        count: "12+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "projectsIcon",
        count: "55+",
        label: "React Native Projects Completed",
      },
      {
        image: PNG38,
        alt: "mobilePro",
        count: "300+",
        label: "Custom React Native App Projects",
      },
    ];
  
    const heading = "Raising The Bar For React Native Development Expertise";
  
    const testimonials = [
      {
        company: "Anyvision",
        content:
          "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
        image: MopePNG,
        alt: "React Native App Development Comapny In Oakville Ilford UK",
      },
      {
        company: "Enterprise E-Commerce",
        content:
          "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
        image: PushPNG,
        alt: "React Native App Development Comapny In Oakville Ilford UK",
      },
      {
        company: "Glitty",
        content:
          "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
        image: PNG876,
        alt: "React Native App Development Comapny In Oakville Ilford UK",
      },
      {
        company: "Rogi",
        content:
          "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
        image: SinglePNG,
        alt: "React Native App Development Comapny In Oakville Ilford UK",
      },
    ];
  return (
    <>
     <Header />
      <HeroBannerSixNativeTech
        title="React Native"
        description="Driven by desire, we deliver and delight the users with our React Native 
                     development services. Keeping the Hybrid app development commitment we harness
                      the power of React Native and deliver an application with perfect customer experience,
                       quick to market, and compatible on native platforms. On the couch or on the go,
                     with Android or with iPhone, we are leading React Native Development 
                     Company taking your VISION to VALUE with react-native quality application."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  )
}

export default ReactNative