import React from "react";
import { NavLink } from "react-router-dom";
import imgHastech from "../../images/assets/hastech-stuff.png";
import img163 from "../../images/shape/163.svg";
import img164 from "../../images/shape/164.svg";

const FancyShortBanner = () => {
  return (
    <>
      <div className="fancy-short-banner-seven md-mt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-7">
              <figure className="wp-block-image size-full">
                <img
                  src={imgHastech}
                  alt="Best Web And App Development Agency In Ilford UK"
                />
              </figure>
            </div>

            <div className="col-lg-6 ml-auto">
              <h2>
                <strong>
                  <span className="has-inline-color has-black-berry-color">
                    Let�s develop something awesome together
                  </span>
                  
                </strong>
              </h2>

              <div className="gtool-spacer" style={{height: "10px"}}>
                <span className="gtool-spacer-mobile"></span>
              </div>

              <p className="has-xlarge-font-size">
                We assist you in creating attractive structures that scale well.
              </p>

              <div className="is-style-outline btn-contact">
                <NavLink to="/contact"
                  className="button__link"
                  href="contact"
                  data-toggle="modal"
                  data-target="#contactModal"
                >
                  Get in touch
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <img src={img163} alt="" className="shapes shape-two" />

        <img src={img164} alt="" className="shapes shape-three" />
      </div>
    </>
  );
};

export default FancyShortBanner;
