import React from "react";
import Header from "../Common/Header";
import FancyShortBanner from "../Home/FancyShortBanner";
import Footer from "../Common/Footer";
import HeroBannerSix from "./HeroBannerSix";
import RowGalley from "./RowGalley";

const Portfolio = () => {
  return (
    <>
      <>
        <Header />
        <HeroBannerSix />
        <RowGalley />
        <FancyShortBanner />
        <Footer />
      </>
    </>
  );
};

export default Portfolio;
