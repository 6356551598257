import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import pluginPNG from "../../../../images/assets/plugin.png";
// import EcomPNG from "../../../../images/assets/ecommerce.png";
import ZendPNG from "../../../../images/assets/zend.png";
import umsPNG from "../../../../images/assets/ums.png";
// import SqlPNG from "../../../../images/assets/sql23.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
// import PNGmb34 from "../../../../images/assets/mb34.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
// import CodegniterPNG from "../../../../images/assets/codegniter.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const ZendPage = () => {
  const tagLines = "Our Zend Expertise";
  const title = "We are Zend Like experts when it comes to Zend Framework";
  const description = `Zend is a popular MVC-based PHP framework that is easy to use and has a low 
                        footprint. We use Zend for our high-end web applications, which are enriched with 
                        state-of-the-art features. Our team of Zend developers has years of experience
                        and frontline expertise in building Zend-based web apps. We take care of the
                          entire development life cycle up to testing, maintenance, and updates, 
                          and follow best development practices.  `;
  const services = {
    title: "Zend Development Services",
    tagLines: "Zend Development With Professional Excellence",
    items: [
      {
        title: "Zend Development",
        imageSrc: softPNG,
        imageAlt:
          "Zend Development",
      },
      {
        title: "Zend Mobile app development",
        imageSrc: umsPNG,
        imageAlt: "Zend Mobile app development",
      },
      {
        title: "Enterprise Zend Development",
        imageSrc: pluginPNG,
        imageAlt: "Enterprise Zend DevelopmentL",
      },
      {
        title: " Zend Framework Customization",
        imageSrc: PNG58,
        imageAlt: "Zend Framework Customization",
      },
      {
        title: "Zend Framework Customization",
        imageSrc: PNG890,
        imageAlt: "Zend Framework Customization",
      },
      {
        title: "Zend Framework Customization",
        imageSrc: PNG78,
        imageAlt: "Zend Framework Customization",
      },
      {
        title: "Rich Internet Applications (RIA)",
        imageSrc: PNG984,
        imageAlt: "Rich Internet Applications (RIA)",
      },
      {
        title: "Zend Application Integration & Maintenance",
        imageSrc: PNG1221,
        imageAlt:
          "Zend Application Integration & Maintenance",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Zend Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Zend Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Zend Projects",
    },
  ];

  const heading = "Raising The Bar For Zend Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Zend"
        description="Don’t just choose any web development company– it is worth it to choose a partner 
                      who is an expert in the required technology and is a passionate executor. Let’s innovate your 
                      custom web platform with the leading Zend Development Company."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={ZendPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default ZendPage;
