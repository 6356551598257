// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const TestimonialSection = (props) => {
//   const { testimonials } = props;

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 1000, 
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 1000,
//   };

//   return (
//     <div>
//     <section className="spacer" >
//       <div className="testimonial-section" style={{display:"flex", justifyContent:"center", alignItems:"center"  }}>
//         <div className="testi-user-img">
//           <Slider {...sliderSettings}>
//             {testimonials.map((testimonial, index) => (
//               <div className="swiper-slide" key={index}>
//                 <img
//                   className={`u${index + 1}`}
//                   src={testimonial.image}
//                   alt={testimonial.alt}
//                 />
//               </div>
//             ))}
//           </Slider>
//         </div>

//         <div className="user-saying">
//           <Slider {...sliderSettings}>
//             {testimonials.map((testimonial, index) => (
//               <div className="swiper-slide" key={index}>
//                 <div className="quote">
//                   <h2>{testimonial.company}</h2>
//                   <p>{testimonial.content}</p>
//                 </div>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </div>

//     </section>
//     </div>
//   );
// };

// export default TestimonialSection;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSection = (props) => {
  const { testimonials } = props;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 3000, 
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div>
    <section className="spacer" >
      <div className="testimonial-section" style={{display:"flex", justifyContent:"center", alignItems:"center"  }}>
        <div className="testi-user-img">
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div className="swiper-slide" key={index}>
                <img
                  className={`u${index + 1}`}
                  src={testimonial.image}
                  alt={testimonial.alt}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="user-saying">
          <Slider {...sliderSettings}>
            {testimonials.map((testimonial, index) => (
              <div className="swiper-slide" key={index}>
                <div className="quote">
                  <h2>{testimonial.company}</h2>
                  <p>{testimonial.content}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

    </section>
    </div>
  );
};

export default TestimonialSection;