import React from "react";

const ContactHeroBanner = () => {
  return (
    <>
      <div className="hero-banner-six cn-bnr">
        <div className="container">
          <div className="row align-items-stretch">
            <div className="col-md-12">
              <div className="inner-left">
                <div
                  className="inner-head-title text-center"
                  data-splitting="true"
                >
                  <div className="banner-pattern centered_lines">
                    <div className="pattern_inner">
                      <span></span>
                      <div className="line line1"></div>
                      <div className="line line2"></div>
                      <div className="line line3"></div>
                    </div>
                  </div>
                  <h1 className="font-recoleta f-s">
                    We are ready to Lead You
                    <br /> into the future of Technology
                    <br />
                    Let's start the discussion
                  </h1>
                  <div className="field">
                    <div className="mouse"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHeroBanner;
