import React from "react";

const HeroBannerSix = () => {
  return (
    <>
      <div className="hero-banner-six">
        <div className="container">
          <div className="row align-items-stretch">
            <div className="col-md-12">
              <div className="inner-left">
                <div
                  className="inner-head-title text-center"
                  data-splitting="true"
                >
                  <div className="banner-pattern centered_lines">
                    <div className="pattern_inner">
                      <span></span>

                      <div className="line line1"></div>

                      <div className="line line2"></div>

                      <div className="line line3"></div>
                    </div>
                  </div>

                  <h1 className="font-recoleta f-s">
                    <span>
                      Have A Look At Our <br />
                      (Beautiful Work)
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBannerSix;
