import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ContainerNativeTech from "../components/ContainerNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import ipadDesign from "../../../../../images/assets/ipad.png";
import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";

import softPNG from "../../../../../images/assets/soft.png";
import extPNG from "../../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
import PNG58 from "../../../../../images/assets/58.png";
import PNG890 from "../../../../../images/assets/890.png";
import PNG984 from "../../../../../images/assets/984.png";
import PNG78 from "../../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";

const IpadPage = () => {
  const tagLines = "Our ipad Excellence";
  const title = "We Set Beautiful Standards for Mature iPad Applications";
  const description = `There is no substitute for ipad when it comes to apps. 
   Apple's long-standing design and functionality standards still hold the fort. 
   No wonder, today more than 55% of U.S. residents use iPad, so, not just having
   a business app becomes a priority but having an interactive, innovative app is 
   required to stand out. We are here to help you come up with an idea for an
   upcoming successful iPad application. We work with clients to define and 
   enable ideation, from testing to monitoring, from ensuring current market
   relevance to marketing.`;
  const services = {
    title: "iPad App Development Services",
    tagLines: " iPad Development With Professional Excellence",
    items: [
      {
        title: "iPad Application Development",
        imageSrc: softPNG,
        imageAlt:
          "Best iPad Application Development Agency Nearby Ilford UK",
      },
      {
        title: " iPad App Optimisation",
        imageSrc: extPNG,
        imageAlt: "Best iPad App Optimisation Services Near Ilford UK",
      },
      {
        title: "iPad app UI/UX Design",
        imageSrc: uiPNG,
        imageAlt:"Best ipad app UI/UX Design Services Nearby Ilford UK",
      },
      {
        title: "Widget Development",
        imageSrc: PNG58,
        imageAlt:"Ipad Widget Development Agency Near Ilford UK",
      },
      {
        title: "iPad app Support, Optimization, and Maintenance",
        imageSrc: PNG890,
        imageAlt:
          "Ipad App Support and Maintenance Services Provider Near Ilford UK",
      },
      {
        title: "iPad App testing",
        imageSrc: PNG78,
        imageAlt:
          "Top iPad App Testing Developer Near Ilford UK",
      },
      {
        title: "iPad App Integration",
        imageSrc: PNG984,
        imageAlt:
          "iPad App Integration Services Provider In Oakville, UK",
      },
      {
        title: "iPad App Prototyping",
        imageSrc: PNG1221,
        imageAlt: "iPad App Prototyping Freelancer In Oakville Ilford",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "Top ipad Developer Near Me Oakville, Ilford, UK",
      count: "150+",
      label: " iPad Experts",
    },
    {
      image: PNG81,
      alt: "Experience Freelancer For ipad Developer Near Me Ilford",
      count: "100+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "ipad App Projects Management Services In UK",
      count: "150+",
      label: "iPad Projects Completed",
    },
    {
      image: PNG38,
      alt: "Custom ipad Project Maker Neaby Ilford UK",
      count: "300+",
      label: "Custom iPad Projects",
    },
  ];

  const heading = "Raising The Bar For iPad Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Best Quality iPad Application Developers In Oakville, Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Best Quality iPad Application Freelancer In Oakville, Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Best Quality iPad Application Agency In Oakville, Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Best Quality iPad Application Company In Oakville, Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="iPad App"
        description="As a leading on of the Best iPad App Development Agency In Ilford UK, we get into the nitty-gritty of different approaches from design to usability, performance to content to deliver for custom iPad applications. Our consultations are proactive and we are always ready to hear about your project."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={ipadDesign}
        imageAlt="Best iPad App Development Agency In Oakville, Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default IpadPage;
