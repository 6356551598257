import React from "react";

const Container = () => {
  return (
    <>
      <div className="container mini-container pt-60 pb-100 left-sq">
        <div className="sec-tag-lines">
          <span style={{color:"white"}}>Our Mission</span>
          <h3 className="visitors-to-customers"></h3>
          <p>
            <span style={{color:"white"}}>
              In the 21st century, technology has become a crucial part of our
              lives. We need to keep up with the latest trends and technologies
              to stay ahead of the competition. This is why we build SMART app
              and websites that are tailored to meet your needs. Our apps and
              websites are designed with the latest technologies such as AI,
              machine learning, and big data to ensure that they are fast,
              secure, and user-friendly. We also use advanced analytics tools to
              track user behavior and optimize performance for maximum impact.
              With our SMART app and website solutions, you can be sure that you
              have a reliable platform that will help you reach your goals in no
              time!
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Container;
