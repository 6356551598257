import React, { useState } from "react";
import logo from "../../images/logo/logo.png";
import { NavLink } from "react-router-dom";
import ModalComponent from "../ScheduleCall/ModalComponent";
import userimg from "../../../src/images/icon/userimage.png";

const Header = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="theme-main-menu sticky-menu theme-menu-five">
        <div className="d-flex align-items-center justify-content-center">
          <div className="logo">
            <NavLink to="/">
              <img
                src={logo}
                alt="Best Software Development Company In Ilford, UK- HAStech Solutions Logo"
              />
            </NavLink>
          </div>

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="nav-container">
              <button
                className={`navbar-toggler ${isMenuOpen ? "active" : ""}`}
                onClick={toggleMenu}
              >
                <span></span>
              </button>

              <div
                className={`navbar-collapse collapse ${
                  isMenuOpen ? "show" : ""
                }`}
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <ul
                    className="navbar-nav main-side-nav font-recoleta"
                    id="one-page-nav"
                  >
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/"
                        onClick={toggleMenu}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/about"
                        onClick={toggleMenu}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/service"
                        onClick={toggleMenu}
                      >
                        Services
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/portfolio"
                        onClick={toggleMenu}
                      >
                        Portfolio
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/contact"
                        onClick={toggleMenu}
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <div>
            <div className="right-widget">
              <button
                type="button"
                onClick={togglePopup}
                className="demo-button"
                data-toggle="modal"
                data-target="#contactModal"
              >
                <span>Schedule a Call</span>
                <img src={userimg} />
              </button>
            </div>
            {isPopupVisible && <ModalComponent togglePopup={togglePopup} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
