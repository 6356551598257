import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import ResponsivePNG from "../../../../images/assets/responsive.png";
import extPNG from '../../../../images/assets/ext.png';
import uiPNG from '../../../../images/assets/ui.png';

const ResponsiveDesignPage = () => {
  const tagLines = "Our Responsive Web Design Excellence";
  const title =
    "Responsive Web Design Services That Remove The Barriers Between Customers & Business";
  const description = `We design with the specific target audience in mind, using adaptable web design services to avoid wasting time on needless design work on each platform. Users can enjoy the latest and greatest design we have imagined, and make a lot of money from it thanks to our creative abilities. `;
   const services = {
    title: "Responsive Web Design Development Services",
    tagLines: "  Next-Gen Responsive Web Design Development With Professional Excellence",
    items: [
      {
        title: "Responsive Web Design Services",
        imageSrc: softPNG,
        imageAlt:
          "Responsive Web Design Services",
      },
      {
        title: "Interface Design Services",
        imageSrc: extPNG,
        imageAlt: "Interface Design Services",
      },
      {
        title: "Feature-Rich And Advanced Web Design",
        imageSrc: uiPNG,
        imageAlt: "Feature-Rich And Advanced Web Design",
      },
      {
        title: "Highly Impressive and Professional Design",
        imageSrc: PNG58,
        imageAlt: "Highly Impressive and Professional Design",
      },
      {
        title: "Landing Page Redesign & Optimization",
        imageSrc: PNG890,
        imageAlt:
          "Landing Page Redesign & Optimization",
      },
      {
        title: "User Interface & Front-end Design",
        imageSrc: PNG78,
        imageAlt:
          "User Interface & Front-end Design",
      },
      {
        title: "Responsive Mobile App Integration",
        imageSrc: PNG984,
        imageAlt:
          "Responsive Mobile App Integration Services Provider In Oakville, UK",
      },
      {
        title: "Responsive Mobile App Prototyping",
        imageSrc: PNG1221,
        imageAlt: "Responsive Mobile App Prototyping Freelancer In Oakville Ilford",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Responsive Web Design Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Responsive Web Design Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Responsive Web Design Projects",
    },
  ];

  const heading = "Raising The Bar For Responsive Web Design Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Responsive Web Design"
        description="People who grew up as digital natives with access to the web and smartphones
         from their childhood must be considered while designing and developing any website or mobile
          app. Understanding this, we need to focus on Responsive web design. Our design experts help you to
         optimize the standards for any platform or device, helping you reach a large audience."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={ResponsivePNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default ResponsiveDesignPage;
