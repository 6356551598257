import React from "react";
import web2 from "../../images/web2.gif";
import e12 from "../../images/e12.gif";
import app1 from "../../images/app1.gif";
import d11 from "../../images/d11.gif";
import img151 from "../../images/shape/151.svg";
import { NavLink } from "react-router-dom";

const FeatureTwelve = () => {
  return (
    <>
      <div className="fancy-feature-twelve mt-250 md-mt-50" id="service">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-10 col-md-8 m-auto">
                <div className="title-style-six text-center">
                  <h2>
                    Check What We
                    <br /> <span>Provide</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center pt-50 md-pt-20">
              <div className="col-lg-3 col-md-6">
                <div className="block-style-seventeen mt-40">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img src={web2} alt="Web Development Comapny In UK" />
                  </div>

                  <div className="static-text">
                    <h3>Web Development</h3>

                    <p>
                      Refers To The Building, Creating, and Maintaining Of
                      Beautiful Websites.
                    </p>
                  </div>

                  <div className="hover-text">
                    <ul>
                      <li>
                        <NavLink to="/">CMS</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Open Source Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Full-Stack Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Wordpress Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/reactjs">JavaScript Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/responsivedesign">
                          Custom Website Design
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="block-style-seventeen mt-40">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img
                      src={e12}
                      alt="Top Web Development Comapny In UK"
                    />
                  </div>

                  <div className="static-text">
                    <h3>E-commerce Development</h3>

                    <p>
                      Which An Internet Retail Company Is Set Up and How It
                      Operates.
                      <br />
                      Properly.
                    </p>
                  </div>

                  <div className="hover-text">
                    <ul>
                      <li>
                        <NavLink to="/">Shopify Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Magento Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Drupal Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">Joomla Development</NavLink>
                      </li>
                      <li>
                        <NavLink to="/">WooCommerce Development</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /.block-style-seventeen --> */}
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="block-style-seventeen mt-40">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img
                      src={app1}
                      alt="Top Web Development Comapny In UK"
                    />
                  </div>

                  <div className="static-text">
                    <h3>Mobile App Development</h3>

                    <p>
                      Mobile Apps Use A Network Connection To Access Remote
                      Computing Resources.
                    </p>
                  </div>

                  <div className="hover-text">
                    <ul>
                      <li>
                        <NavLink to="/php">Custom Applications</NavLink>
                      </li>
                      <li>
                        <NavLink to="/mobileappdesign">
                          Mobile App Designing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reactnative">
                          iOS Mobile Application
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/android">Android Mobile</NavLink>
                      </li>
                      <li>
                        <NavLink to="/reactnative">React Native Mobile</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /.block-style-seventeen --> */}
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="block-style-seventeen mt-40">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <img
                      src={d11}
                      alt="Top Web Development Comapny In UK"
                    />
                  </div>

                  <div className="static-text">
                    <h3>Digital Marketing</h3>

                    <p>
                      Make Your Business <br />
                      Presence In <br />
                      Online World <br />
                      To Reach Your Goals.
                    </p>
                  </div>

                  <div className="hover-text">
                    <ul>
                      <li>
                        <NavLink to="/searchengineoptimization">
                          Search Engine Optimization(SEO)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/socialmediamarketing">
                          Search Engine Marketing(SEM)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/socialmediaoptimizing">
                          Social Media Optiization <br />
                          (SMO)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/payperclick">Pay-Per-Click(PPC)</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /.bg-wrapper --> */}

        <img
          src={img151}
          alt="Top Web Development Comapny In Ilford"
          className="shapes shape-one"
        />
      </div>
    </>
  );
};

export default FeatureTwelve;
