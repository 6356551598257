import React from 'react'
import Header from '../../../../../src/components/Common/Header'
import Footer from '../../../../../src/components/Common/Footer'
import HeroBannerSixNativeTech from '../components/HeroBannerSixNativeTech'
import ContainerMiniLeftNativeTech from '../components/ContainerMiniLeftNativeTech'
import ContainerNativeTech from '../components/ContainerNativeTech'
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import TestimonialSection from "../components/TestimonialSection";
import androidDesign from "../../../../images/assets/android12.png"
import softPNG from "../../../../images/assets/soft.png";

import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";

import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import img00 from "../../../../images/assets/00.png"
import img1209 from "../../../../images/assets/1209.png"
import img28 from "../../../../images/assets/28.png"

import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";


const SearchEngineOptimization = () => {

    const tagLines = 'Our SEO Excellence';
  const title = 'A Potent SEO Company Delivering Polished Websites Rank';
  const description = [
    `It's time to "Make Your Websites Rank". Hastech loves such Goals. With the benchmark of competence, our SEO Specialists team fulfills the aim with epic ideas storming on your needs, where to start, where to go, and how to get you there. Our SEO services are all about enticing, gaining organic traffics, and retaining users on a vast platform of millions of users. We will take your website from success with key aspects of acquisition, interaction, and retention. This is what fuels both your and our success.`
  ];
  const services = {
    title: 'A Search Engine Optimization Services',
    tagLines: 'Search Engine Optimization Services With Professional Excellence',
    items: [
      {
        title: 'Enterprise Search Engine Optimization ',
        imageSrc: softPNG,
        imageAlt: 'Best Enterprise Android App Development Nearby Ilford UK'
      },
      {
        title: ' Search Engine Optimization',
        imageSrc: img00,
        imageAlt: 'Search Engine Optimization Near Me Ilford UK'
      },
      {
        title: 'Search Engine Optimization Migration',
        imageSrc: img1209,
        imageAlt: 'Search Engine Optimization In Ilford UK'
      },
      {
        title: 'Search Engine Optimization Applications',
        imageSrc: PNG58,
        imageAlt: 'Best Search Engine Optimization Nearby Ilford UK'
      },
      {
        title: ' Search Engine Optimization Testing',
        imageSrc: img28,
        imageAlt: 'Quality Search Engine Optimization Ilford UK'
      },
      {
        title: ' Search Engine Optimization Maintenance & Support',
        imageSrc: PNG78,
        imageAlt: 'Search Engine Optimization Agency In UK'
      },
      {
        title: ' Search Engine Optimization Design',
        imageSrc: uiPNG,
        imageAlt: 'Best Search Engine Optimization In Ilford UK'
      },
      {
        title: 'Search Engine Optimization Integrations',
        imageSrc: PNG1221,
        imageAlt: 'Top Search Engine Optimization Service Provider Nearby Ilford UK'
      }
    ],
    buttonText: 'Inquire Now',
    buttonLink: 'https://hastechsolutions.ca/contact.html'
    };  
  const stats =  [
      {
        image: PNG12,
        alt: "Top Android Experts Near Me Ilford UK",
        count: "20+",
        label: "SEO Experts",
      },
      {
        image: PNG81,
        alt: "Experience Freelancer For Android App Development In UK",
        count: "15+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "Android App Projects Management Services In UK",
        count: "150+",
        label: "SEO Projects Completed",
      },
      {
        image: PNG38,
        alt: "Custom Mobile App Project Maker Neaby Ilford UK",
        count: "300+",
        label: "Custom SEO Projects",
      },
                 
                      ];   

      const heading = "Raising The Bar For Search Engine Optimization Expertise";
      
      const testimonials = [
        {
          company: "Anyvision",
          content:
            "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
          image: MopePNG,
          alt: "Best Quality Android Application Developers In Oakville, Ilford UK",
        },
        {
          company: "Enterprise E-Commerce",
          content:
            "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
          image: PushPNG,
          alt: "Best Quality Android Application Freelancer In Oakville, Ilford UK",
        },
        {
          company: "Glitty",
          content:
            "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
          image: PNG876,
          alt: "Best Quality Android Application Agency In Oakville, Ilford UK",
        },
        {
          company: "Rogi",
          content:
            "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
          image: SinglePNG,
          alt: "Best Quality Android Application Company In Oakville, Ilford UK",
        },
                     ];   

  return (
    <>
      <Header />
      
      <HeroBannerSixNativeTech
      title=" Search Engine Optimization"
      description="With thousands of websites in the market, you need a professional search engine optimization company to rank your
                    websites organically. By adding the element of WOW, we develop websites that people search with keywords
                      and results or on top in the search engine research pages. We take you beyond the bounds of excellence
                      and our SEO services accept nothing less. "
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={androidDesign}
      imageAlt="Best Search Engine Optimization Agency In Ilford UK"
    />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer/>
    </>
  )
}

export default SearchEngineOptimization