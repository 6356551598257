import React from 'react'
import Header from '../../../../Common/Header'
import Footer from '../../../../Common/Footer'
import HeroBannerSixNativeTech from '../components/HeroBannerSixNativeTech'
import ContainerMiniLeftNativeTech from '../components/ContainerMiniLeftNativeTech'
import ContainerNativeTech from '../components/ContainerNativeTech'
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import TestimonialSection from "../components/TestimonialSection";
import androidDesign from "../../../../../images/assets/android12.png"
import softPNG from "../../../../../images/assets/soft.png";
// import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
import PNG58 from "../../../../../images/assets/58.png";
// import PNG890 from "../../../../images/assets/890.png";
// import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import img00 from "../../../../../images/assets/00.png"
import img1209 from "../../../../../images/assets/1209.png"
import img28 from "../../../../../images/assets/28.png"

import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";

import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";




const AndroidPage = () => {
  const tagLines = 'Our Android Excellence';
  const title = 'A Potent Android App Development Company Delivering Polished App';
  const description = [
    `With 3 million Android apps existing today, it's time to "Make Yours App Differently". Hastech loves such Goals. 
    With the benchmark of competence, our Android app developer’s team fulfills the aim with epic ideas storming on your needs, 
    where to start, where to go, and how to get you there. Our Android app development services are all about enticing, 
    gaining exposure, and retaining users on a vast platform of 2 million users.`,
    ` Our Android services deliver products with absolute confidence, from Kotlin development to Dart-based execution. 
    We will take your app from success with key aspects of acquisition, interaction, and retention. 
    This is what fuels both your and our success.`
  ];
  const services = {
    title: 'Android App Development Services',
    tagLines: 'Android Development With Professional Excellence',
    items: [
      {
        title: 'Enterprise Android App Development',
        imageSrc: softPNG,
        imageAlt: 'Best Enterprise Android App Development Nearby Ilford UK'
      },
      {
        title: ' Android Full-cycle App development',
        imageSrc: img00,
        imageAlt: 'Android Full-cycle App Development Near Me Ilford UK'
      },
      {
        title: 'Android App Migration',
        imageSrc: img1209,
        imageAlt: 'Android App Migration Freelancer In Ilford UK'
      },
      {
        title: 'Android M-commerce Applications',
        imageSrc: PNG58,
        imageAlt: 'Best Android M-commerce Applications Developer Nearby Ilford UK'
      },
      {
        title: ' App Testing',
        imageSrc: img28,
        imageAlt: 'Quality App Testing Services Provider Near Me Ilford UK'
      },
      {
        title: ' Maintenance & Support',
        imageSrc: PNG78,
        imageAlt: 'Android App Maintenance And Support Agency In UK'
      },
      {
        title: ' UI/UX App Design',
        imageSrc: uiPNG,
        imageAlt: 'Best Android App UI/UX App Design Agency In Ilford UK'
      },
      {
        title: 'Android App API Integrations',
        imageSrc: PNG1221,
        imageAlt: 'Top Android App API Integrations Service Provider Nearby Ilford UK'
      }
    ],
    buttonText: 'Inquire Now',
    buttonLink: 'https://hastechsolutions.ca/contact.html'
    };  
  const stats =  [
      {
        image: PNG12,
        alt: "Top Android Experts Near Me Ilford UK",
        count: "20+",
        label: "Android Experts",
      },
      {
        image: PNG81,
        alt: "Experience Freelancer For Android App Development In UK",
        count: "15+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "Android App Projects Management Services In UK",
        count: "150+",
        label: "Android Projects Completed",
      },
      {
        image: PNG38,
        alt: "Custom Mobile App Project Maker Neaby Ilford UK",
        count: "300+",
        label: "Custom Android App Projects",
      },
                 
                      ];   

      const heading = "Raising The Bar For Android Expertise";
      
      const testimonials = [
        {
          company: "Anyvision",
          content:
            "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
          image: MopePNG,
          alt: "Best Quality Android Application Developers In Oakville, Ilford UK",
        },
        {
          company: "Enterprise E-Commerce",
          content:
            "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
          image: PushPNG,
          alt: "Best Quality Android Application Freelancer In Oakville, Ilford UK",
        },
        {
          company: "Glitty",
          content:
            "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
          image: PNG876,
          alt: "Best Quality Android Application Agency In Oakville, Ilford UK",
        },
        {
          company: "Rogi",
          content:
            "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
          image: SinglePNG,
          alt: "Best Quality Android Application Company In Oakville, Ilford UK",
        },
                     ];     

  return (
    <>
      <Header />
      
      <HeroBannerSixNativeTech
      title="Android App"
      description="With thousands of Android apps in the market, you need a professional Android app development company to launch your Android app in the form of MVP or a full-fledged product. By adding the element of WOW, we develop apps that people love to see again and again. We take you beyond the bounds of excellence and our Android app development services accept nothing less."
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={androidDesign}
      imageAlt="Best Android App Development Agency In Ilford UK"
    />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer/>
    </>
  )
}

export default AndroidPage