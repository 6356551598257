import React from "react";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../../images/assets/ionic.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../../images/assets/soft.png";
import extPNG from "../../../../../images/assets/ext.png";
import uiPNG from "../../../../../images/assets/ui.png";
import PNG58 from "../../../../../images/assets/58.png";
import PNG890 from "../../../../../images/assets/890.png";
import PNG984 from "../../../../../images/assets/984.png";
import PNG78 from "../../../../../images/assets/78.png";
import PNG1221 from "../../../../../images/assets/1221.png";
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";

import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";


const IonicPage = () => {
  const tagLines = "Our Ionic  Excellence";
  const title = "Transform From Viable To WOW With High-Performing Ionic Apps";
  const description = `Our goal is to provide you with the best
                         mobile experience possible. This is why we leverage 
                         many of the Ionic features and develop a set of code that
                          is incredibly smooth on both iOS and Android devices. 
                          Our Ionic app development services can help you
                           get your mobile app up and running quickly and 
                           efficiently with optimized development skills.
                           Our powerful backend makes it easy to get started, and
                         our scalable design is essential for custom mobile app
                        development. We have a great ending to our multi-device 
                        multi-OS testing process in place to ensure your Ionic app is 
                             fantastic.`;
  const services = {
    title: " Ionic App Development Services",
    tagLines: " Ionic Development With Professional Excellence",
    items: [
      {
        title: "Ionic Consulting",
        imageSrc: softPNG,
        imageAlt:
          "Best Ionic App Consulting Agency Nearby Oakville Ilford UK",
      },
      {
        title: "Enterprise app development",
        imageSrc: extPNG,
        imageAlt: "Enterprise Iconic App Development Company In Ilford",
      },
      {
        title: "Ionic UI Development",
        imageSrc: uiPNG,
        imageAlt: "Ionic UI Development Agency Nearby Ilford UK",
      },
      {
        title: "Ionic Widget Development ",
        imageSrc: PNG58,
        imageAlt: "Best Ionic Widget Development Company In UK",
      },
      {
        title: " Support and maintenance",
        imageSrc: PNG890,
        imageAlt:
          "Support and Maintenance Srvice Provider For Iconic App Development Nearby Ilford Support and maintenance",
      },
      {
        title: "Ionic Migration Services",
        imageSrc: PNG78,
        imageAlt: "Ionic App Migration Services In UK",
      },
      {
        title: "Ionic App Integration",
        imageSrc: PNG984,
        imageAlt: "Best Ionic App Integration Developer Nearby Ilford UK",
      },
      {
        title: " Ionic QA & Testing ",
        imageSrc: PNG1221,
        imageAlt: "Top Ionic QA and Testing Agency In Oakville Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Ionic Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Ionic Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Ionic App Project",
    },
  ];

  const heading = "Raising The Bar For Ionic Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Ionic App"
        description="Take your concept to the small screen with big pictures on the cross platforms with our Ionic Development Services. Developing flexible cross-platform apps isn’t easy but using them should be. We make the end user experience easy by delivering mobile apps that guarantee platform-specific functionality and native like look with high-code reusability. We are an Ionic App Development Company that accepts nothing LESS."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Ionic App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default IonicPage;
