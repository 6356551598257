import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/node2.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGserver from "../../../../images/assets/server.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNGform from "../../../../images/assets/form.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGap2 from "../../../../images/assets/ap2.png";
import PNGplugin from "../../../../images/assets/plugin.png";
import PNGback from "../../../../images/assets/back.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const NodeJs = () => {
    const tagLines = "Our NodeJs Excellence";
    const title = "Top NodeJS Web Development Company";
    const description = `NodeJS is a well-liked technology due to its adaptability, 
    flexibility, and capacity for change. You can employ an agile strategy
    with quicker and concurrent testing and implementation of updates by working
    with a NodeJS development business. You may create complex, enormously scalable,
    and real-time non-buffering apps with our best-in-class NodeJS services.`;
    const services = {
      title: " Node Js App Development Services",
      tagLines: " Node Js Development With Professional Excellence",
      items: [
        {
          title: "Nodejs API and Package Development",
          imageSrc: PNGap2,
          imageAlt:
            "Nodejs API and Package Development Agency In Oakville Ilford UK",
        },
        {
          title: "Nodejs Server Side Development",
          imageSrc: PNGserver,
          imageAlt: "Node.js Server Side Development Company Nearby Oakville Ilford UK",
        },
        {
          title: "Nodejs Plugin Development",
          imageSrc: PNGplugin,
          imageAlt: "Node.js Plugin Development Company Near Ilford UK",
        },
        {
          title: "Back-end Dashboards Development ",
          imageSrc: PNGback,
          imageAlt: "Best Ionic Widget Development Company In UK",
        },
        {
          title: "  Nodejs Integration Solution",
          imageSrc: PNG78,
          imageAlt:
            "Node.js Integration Solution Provider Nearby Oakville Ilford UK",
        },
        {
          title: "Nodejs CMS Development",
          imageSrc: PNGform,
          imageAlt: "Node.js CMS Development Agency In Ilford UK",
        },
        {
          title: "Nodejs API Development and Integration",
          imageSrc: PNG984,
          imageAlt: "Node.js API Development and Integration Services Provider In UK",
        },
        {
          title: " Nodejs For UI/UX Development ",
          imageSrc: uiPNG,
          imageAlt: "Node.js for UI/UX Development Freelancer Near Ilford UK",
        },
      ],
      buttonText: "Inquire Now",
      buttonLink: "https://hastechsolutions.ca/contact.html",
    };
    const stats = [
      {
        image: PNG12,
        alt: "expertIcon",
        count: "15+",
        label: "NodejS Experts",
      },
      {
        image: PNG81,
        alt: "experienceIcon",
        count: "12+",
        label: "Years Of Experience",
      },
      {
        image: PNG36,
        alt: "projectsIcon",
        count: "55+",
        label: "NodejS Projects Completed",
      },
      {
        image: PNG38,
        alt: "mobilePro",
        count: "300+",
        label: "Custom NodeJs Projects",
      },
    ];
  
    const heading = "Raising The Bar For Node Js Expertise";
  
    const testimonials = [
      {
        company: "Anyvision",
        content:
          "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
        image: MopePNG,
        alt: "Ionic App Development Comapny In Oakville Ilford UK",
      },
      {
        company: "Enterprise E-Commerce",
        content:
          "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
        image: PushPNG,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
      {
        company: "Glitty",
        content:
          "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
        image: PNG876,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
      {
        company: "Rogi",
        content:
          "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
        image: SinglePNG,
        alt: "Ionic App Development Freelancer In Oakville Ilford UK",
      },
    ];
  return (
    <>
    <Header />
    <HeroBannerSixNativeTech
      title="Node Js Development"
      description="We push your idea through our comprehensive and efficient NodeJS development
                     services powered by adroit trained resources, who are proficient with the depths of technology."
      buttonText="Inquire Now"
      buttonLink="https://hastechsolutions.ca/contact.html"
      imageSrc={designPNG}
      imageAlt="Best NodeJS Web Development Company Nearby Ilford UK"
    />
    <ContainerMiniLeftNativeTech
      tagLines={tagLines}
      title={title}
      description={description}
    />
    {/* <ContainerNativeTech/> */}
    <ContainerNativeTech services={services} />
    {/* <ServiceStateNativeTech/> */}
    <ServiceStateNativeTech stats={stats} heading={heading} />
    {/* <TestimonialNativeTech/> */}
    <TestimonialSection testimonials={testimonials} />;
    <Footer />
  </>
  )
}

export default NodeJs