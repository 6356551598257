import React from "react";
import image1 from "../../images/assets/images1.svg"
import image2 from "../../images/assets/images2.svg"
import image3 from "../../images/assets/images3.svg"

const AboutThings = () => {
  return (
    <>
      <div className="things">
        <div className="container-things mt-5">
          <div className="sec-title rtop-line">
            <h3 style={{ textAlign: "center" }}>How We Make A Difference</h3>
          </div>
        </div>

        <div className="container-solve">
          <div className="row about-tags align-items-center">
            <div className="col-md-6 col-sm-6 col-12 tag-image">
              <img
                src={image1}
                alt="Best Web And App Development Agency In Ilford UK"
                width="410"
                height="369"
              />
            </div>

            <div className="col-md-6 col-sm-6 col-12 tag-about-left">
              <div className="sec-title">
                <h3 className="fs-24">What We Do</h3>

                <p>
                  We take pride in delivering world-class innovative digital
                  solutions that have a significant impact. We've collaborated
                  with Top companies, SMEs, and startups to add value through
                  technology, strategies, and creativity. through technology,
                  strategies and creativity.
                </p>
              </div>
            </div>
          </div>

          <div className="row about-tags-2 align-items-center">
            <div className="col-md-6 col-sm-6 col-12 tag-about-left tag-purple">
              <div className="sec-title">
                <h3 className="fs-24">How We Do</h3>

                <p>We take a time tested Why-What-How approach.</p>

                <p>Step 1 is to understand WHY something needs to be done.</p>

                <p>Step 2 is to discuss &amp; define WHAT needs to be done.</p>

                <p>Step 3 is to execute it.</p>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-12 tag-image">
              <img
                src={image2}
                alt="Best Web And App Development Agency In Ilford UK"
                width="399"
                height="341"
              />
            </div>
          </div>

          <div className="row about-tags align-items-center">
            <div className="col-md-6 col-sm-6 col-12 tag-image">
              <img
                src={image3}
                alt="Best Web And App Development Agency In Ilford UK"
                width="393"
                height="369"
              />
            </div>

            <div className="col-md-6 col-sm-6 col-12 tag-about-left tag-yellow">
              <div className="sec-title">
                <h3 className="fs-24">Why HASTech?</h3>

                <p>
                  Our work is focused on the customer first. We take pride in
                  our proactive communication and the high quality of our work.
                  Each project is delivered with utmost care to ensure that the
                  customer is satisfied.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutThings;
