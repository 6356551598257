import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import designPNG from "../../../../images/assets/express.png";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNGserver from "../../../../images/assets/server.png";
import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";
import PNGform from "../../../../images/assets/form.png";
import PNGsoft from "../../../../images/assets/soft.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import PNGap2 from "../../../../images/assets/ap2.png";
import PNG28 from "../../../../images/assets/28.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG23 from "../../../../images/assets/23.png";
import PNGcross from "../../../../images/assets/23.png";
import PNGplugin from "../../../../images/assets/plugin.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import PNGecom from "../../../../images/assets/ecommerce.png";


const ExpressJs = () => {
    const tagLines = "Our Express JS Excellence";
  const title = "Leveling Up Businesses With Class ExpressJS Application Development";
  const description = `As ExpressJS Development Company, we are committed to helping 

  our clients achieve their business goals with the speed and scalability

  of ExpressJS. We take pride in being able to turn your business objectives

  into a flawless ExpressJS development solution, as our go-to choice for 

  ExpressJS development services. Our ExpressJS developers go above and beyond 

  to support high-end growth for your businesses..`;

  const services = {
    title: " ExpressJs Development Services",
    tagLines: " ExpressJs Development With Professional Excellence",
    items: [
      {
        title: "Express.JS Frameworks Development",
        imageSrc: PNGsoft,
        imageAlt: "Express.JS Frameworks Development Company In Ilford UK",
      },
      {
        title: "Express JS Development Services",
        imageSrc: PNGform,
        imageAlt: "Express JS Development Services Agency In Ilford UK",
      },
      {
        title: " Hire Express JS Developer",
        imageSrc: PNG58,
        imageAlt:
          "Hire Express JS Developer In Ilford UK",
      },
      {
        title: "Express.JS Web Application Development ",
        imageSrc: PNGplugin,
        imageAlt: "MeteorJS Support And Maintenance Agency In Ilford UK",
      },
      {
        title: "   ExpressJS Frameworks Development",
        imageSrc: PNG890,
        imageAlt: "ExpressJS Frameworks Development Service Provider In UK",
      },
      {
        title: "API Development & Integration",
        imageSrc: PNG78,
        imageAlt:
          "API Development & Integration Agency In Ilford UK",
      },
      {
        title: "ExpressJS Application Development",
        imageSrc: PNG984,
        imageAlt:
          "ExpressJS Application Development Company In Ilford UK",
      },
      {
        title: " ExpressJS Application Development ",
        imageSrc: PNGecom,
        imageAlt:
          "ExpressJS Application Development Company In UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "ExpressJS Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "ExpressJS Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom ExpressJS Projects",
    },
  ];

  const heading = "Raising The Bar For ExpressJS Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Express JS Development"
        description="We bring the speed and efficiency of the NodeJs framework – ExpressJS, to the front-end and back-end of your business applications. We understand your idea and application stand higher than the code, so with our quality code, we make every effort to transform your solidified idea into a robust server-side application."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={designPNG}
        imageAlt="Best Express Js App Development Services Provider Nearby Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  )
}

export default ExpressJs