import React from "react";
import img144 from "../../images/shape/144.svg";
import img145 from "../../images/shape/145.svg";
import img146 from "../../images/shape/146.svg";
import img147 from "../../images/shape/147.svg";
import img148 from "../../images/shape/148.svg";
import img149 from "../../images/shape/149.svg";
import img150 from "../../images/shape/150.png";
import touchicom from "../../images/icon/touch.svg";
import screen03 from "../../images/screen_03.gif";
import ser1 from "../../images/assets/ser1.png";
import { NavLink } from "react-router-dom";

const ThemeHeroBanner = () => {
  return (
    <>
      <div className="hero-banner-six">
        <div className="illustration-content">
          <img
            src={img144}
            alt="Software Development Company In Ilford UK"
            className="main-img ml-auto"
          />
          <div className="rt-vv">
            <img
              src={screen03}
              alt="Best Web Development Company In Ilford UK"
              className="screen-one"
            />
          </div>
          <div className="screen-two">
            <img
              src={ser1}
              alt="Freelancer Software Developer Nearby Ilford"
              className="ml-auto"
            />
          </div>

          <img
            src={img145}
            alt="Web Development Comapny In UK"
            className="shapes shape-one"
          />

          <img
            src={img146}
            alt="Web Development Comapny In UK"
            className="shapes shape-two"
          />

          <img
            src={img147}
            alt="Web Development Comapny In UK"
            className="shapes shape-three"
          />

          <img
            src={img148}
            alt="Web Development Comapny In UK"
            className="shapes shape-four"
          />

          <img
            src={img149}
            alt="Web Development Comapny In UK"
            className="shapes shape-five"
          />

          <img
            src={img150}
            alt="Web Development Comapny In UK"
            className="shapes shape-six"
          />

          <div className="shapes oval-one"></div>

          <div className="shapes oval-two"></div>

          <div className="shapes oval-three"></div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <h1 className="font-recoleta hero-heading">
                Build a real-time <span>engagement</span> into your app's &amp;
                website's.
              </h1>

              <p className="hero-sub-heading">
                HAStech delivered blazing fast, striking solutions
              </p>

              <div className="d-sm-flex align-items-center button-group">
               
                <NavLink to='/contact' className="d-flex align-items-center ios-button gr-bg-one hover-reverse-gr-bg-one order-sm-first">
                  <img
                    src={touchicom}
                    alt="Web Development Comapny In UK"
                    className="icon"
                  />

                  <div>
                    <strong>Get in Touch</strong>
                  </div>
                </NavLink>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThemeHeroBanner;
