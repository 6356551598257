import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ContactForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    service: [],
    project: "",
    // Remove document and isSubmitted from formData since they are not used in the form
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let updatedServices = [...formData.service];
    if (checked) {
      updatedServices.push(value);
    } else {
      updatedServices = updatedServices.filter((service) => service !== value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedServices,
    }));
  };

  // ... (Previous code remains unchanged)

  const handleSubmit = (e) => {
    e.preventDefault();

    let validationErrors = {};

    // ... (Previous code remains unchanged)

    if (Object.keys(validationErrors).length === 0) {
      // No errors, submit the form

      // Serialize the form data
      const formDataSerialized = Object.keys(formData)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(formData[key])}`
        )
        .join("&");

      // axios post request with serialized form data
      axios
        .post("https://hastechsolutions.co.uk/php/email.php", formDataSerialized, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
      // axios
      //   .post(
      //     "https://hastechsolutions.co.uk/php/email.php",
      //     formDataSerialized,
      //     {
      //       headers: {
      //         "Content-Type": "application/x-www-form-urlencoded",
      //         "X-Requested-With": "XMLHttpRequest", // Indicate AJAX request
      //       },
      //     }
      //   )
        .then((response) => {
          console.log("Form submitted successfully");
          console.log(response.data);
          setFormData((prevData) => ({
            ...prevData,
            isSubmitted: true,
          }));
          navigate("/thankyou");
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          console.log("error req" + error.request); 
          // Handle error scenarios here
        });
    } else {
      // Errors found, update the errors state
      setErrors(validationErrors);
    }
  };

  // ... (Remaining code remains unchanged)

  return (
    <>
      {/* ... (Remaining JSX code is the same) ... */}
      <div className="clearfix main-cta-form">
        <div className="container mini-container" id="contactForm">
          <div className="cmr-contact left-brush-top">
            <div className="row mb-5 align-items-center">
              <div className="col-md-6 col-sm-6 col-12">
                <div className="sec-title left-brush">
                  <p>
                    <span>Contact Us</span>
                  </p>
                  <h2>
                    Let's Talk <br />
                    <span>Business!</span>
                  </h2>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-12">
                <p className="contact-form-detail mb-0">
                  Have questions about your idea? Drop in your details to
                  discuss with our solution consultants. We look forward to
                  hearing from you.
                </p>
              </div>
            </div>

            {/* <form onSubmit={handleSubmit} action="gourav.php"  method="post"> */}
            <form
              onSubmit={handleSubmit}
              // action="http://localhost/gourav.php"
              // action=""
              // method="post"
            >
              <div className="row m-0">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-inline">
                    <label className="cmr-label">First Name:</label>
                    <input
                      className="form-control"
                      name="first_name"
                      type="text"
                      placeholder="e.g. Ross"
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                    {errors.first_name && (
                      <span className="error-message">{errors.first_name}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-inline">
                    <label className="cmr-label">Last Name:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="last_name"
                      placeholder="e.g. McCarthy"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                    {errors.last_name && (
                      <span className="error-message">{errors.last_name}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-inline">
                    <label className="cmr-label">Email:</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      placeholder="e.g. ross@yopmail.com"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="form-inline">
                    <label className="cmr-label">Contact:</label>
                    <input
                      type="number"
                      className="form-control"
                      name="contact"
                      placeholder="**************"
                      value={formData.contact}
                      onChange={handleInputChange}
                    />
                    {errors.contact && (
                      <span className="error-message">{errors.contact}</span>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-inline nowrap form-service-row">
                    <label className="cmr-label">Services:</label>
                    <div className="checkform">
                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Mobile Development"
                          checked={formData.service.includes(
                            "Mobile Development"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <span>Mobile Development</span>
                      </label>

                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Web Development"
                          checked={formData.service.includes("Web Development")}
                          onChange={handleCheckboxChange}
                        />
                        <span>Web Development</span>
                      </label>
                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Hire Development"
                          checked={formData.service.includes(
                            "Hire Development"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <span>Hire Development</span>
                      </label>
                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Maintenance & Support"
                          checked={formData.service.includes(
                            "Maintenance & Support"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <span>Maintenance & Support</span>
                      </label>
                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Technical Consultancy"
                          checked={formData.service.includes(
                            "Technical Consultancy"
                          )}
                          onChange={handleCheckboxChange}
                        />
                        <span>Technical Consultancy</span>
                      </label>
                      <label className="pure-material-checkbox">
                        <input
                          type="checkbox"
                          name="service"
                          value="Other Services"
                          checked={formData.service.includes("Other Services")}
                          onChange={handleCheckboxChange}
                        />
                        <span>Other Services</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="form-inline nov-center">
                    <label className="cmr-label">About Project:</label>
                    <textarea
                      className="form-control"
                      name="project"
                      placeholder="Description (Optional)"
                      value={formData.project}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-sm-12 text-center">
                  <div className="form-inline mt-2">
                    <label className="cmr-label"></label>
                    <div className="captcha-control">
                      <div>
                        <div>
                          <div style={{ width: "304px", height: "78px" }}>
                            <div>
                              <div
                                className="g-recaptcha"
                                data-sitekey="your-recaptcha-site-key"
                              ></div>
                            </div>
                            <textarea
                              id="g-recaptcha-response-1"
                              name="g-recaptcha-response"
                              className="g-recaptcha-response"
                              style={{
                                width: "250px",
                                height: "40px",
                                border: "1px solid rgb(193, 193, 193)",
                                margin: "10px 25px",
                                padding: "0px",
                                resize: "none",
                                display: "none",
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <span className="error-message captcha-error">
                        {errors.captcha}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 text-center mt-4">
                  <div className="text-center">
                    <button
                      className="cmr-solid-btn hvr-shutter-out-horizontal get-center"
                      name="submit"
                      type="submit"
                    >
                      Submit
                      <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
