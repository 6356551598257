import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import PNGc23 from "../../../../images/assets/c23.png";
import pluginPNG from "../../../../images/assets/plugin.png";
// import EcomPNG from "../../../../images/assets/ecommerce.png";
// import ZendPNG from "../../../../images/assets/zend.png";
// import umsPNG from "../../../../images/assets/ums.png";
// import SqlPNG from "../../../../images/assets/sql23.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNG98 from "../../../../images/assets/98.png";
import Cms34 from "../../../../images/assets/cms34.png";
// import PNGmb34 from "../../../../images/assets/mb34.png";
import PNG984 from "../../../../images/assets/984.png";
// import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
// import CodegniterPNG from "../../../../images/assets/codegniter.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const YiiPage = () => {
  const tagLines = "Our Yii Excellence";
  const title = "Reliable Yii Development Services For A Reliable Framework";
  const description = `Yii PHP framework is among the most reliable open-source technology for application development, and with an ideal
   Yii Development Company like Hastech you can give your business’s arsenal a solid technology base. So, don’t let the complex technicalities or manual processes
   headache hold you back, hire Yii developers to get information technology done for you`;
  const services = {
    title: "Yii Development Services",
    tagLines: "Yii Development With Professional Excellence",
    items: [
      {
        title: "Yii Web Application Development",
        imageSrc: softPNG,
        imageAlt: "Yii Web Application Development",
      },
      {
        title: "Yii Extension Development",
        imageSrc: PNG98,
        imageAlt: "Yii Extension Development",
      },
      {
        title: "Yii Modules/Plugins Development",
        imageSrc: pluginPNG,
        imageAlt: "Yii Modules/Plugins Development",
      },
      {
        title: "Yii CMS Development",
        imageSrc: Cms34,
        imageAlt: "Yii CMS Development",
      },
      {
        title: "Yii Support & Maintenance",
        imageSrc: PNG890,
        imageAlt: "Yii Support & Maintenance",
      },
      {
        title: "Yii Post-Launch Support",
        imageSrc: PNG78,
        imageAlt: "Yii Post-Launch Support",
      },
      {
        title: "Yii 3rd Party App Integrations",
        imageSrc: PNG984,
        imageAlt: "Yii 3rd Party App Integrations",
      },
      {
        title: "Yii Framework Development",
        imageSrc: PNG1221,
        imageAlt: "Yii Framework Development",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Yii Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Yii Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Yii Projects",
    },
  ];

  const heading = "Raising The Bar For Yii Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Yii"
        description="Yii’s powerful capabilities from caching to AJAX compatibility make it a rich-featured PHP framework. Our Yii web development experts make custom web application development easy and tick-mark all the requirement boxes on the wishlist."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={PNGc23}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default YiiPage;
