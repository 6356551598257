import React from "react";
import icon88 from "../../images/icon/88.png";
import icon86 from "../../images/icon/86.svg";
import icon85 from "../../images/icon/85.svg";
import icon159 from "../../images/shape/159.svg";
import icon160 from "../../images/icon/160.png";
import { NavLink } from "react-router-dom";

const PriceSection = () => {
  const style1 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  };
  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "0px",
    height: "0px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0,
  };
  const style3 = {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  };
  const style4 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    pointerEvents: "none",
    backgroundImage:
      "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)",
    width: "0px",
    height: "0px",
    transform: "rotate(180deg) translate(-50%, -50%)",
    transformOrigin: "0% 0%",
    opacity: 0,
  };
  return (
    <>
      <div
        className="pricing-section-five mt-100 pt-150 md-mt-150 sm-mt-80 md-pt-80"
        id="pricing"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 order-lg-last">
              <div className="title-style-six pl-5 md-p0">
                <h6 style={{ fontSize: "15px" }}>Our Pricing</h6>

                <h2>
                  Choose your <span>Product.</span>
                </h2>

                <p className="text-xs"></p>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="pricing-table-area-five md-mt-60">
                <img src={icon88} alt="" className="shapes shape-one" />

                <img src={icon160} alt="" className="shapes shape-two" />

                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="pr-table-wrapper active js-tilt">
                      <img src={icon85} alt="" className="icon" />

                      <div className="pack-name">Dimond Plan</div>

                      <ul className="pr-feature">
                        <li>Unlimited Email</li>

                        <li>5gb Hosting &amp; Domain</li>

                        <li>Email &amp; Live chat.</li>
                      </ul>

                      <div className="price">$59.99</div>

                      <div className="trial-text">
                        up to 10 user + 1.99 per user
                      </div>

                      <NavLink to="/" className="trial-button hover-reverse-gr-bg-one">
                        Sign up
                      </NavLink>

                      <div className="js-tilt-glare" style={style1}>
                        <div className="js-tilt-glare-inner" style={style2}></div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="pr-table-wrapper js-tilt sm-mt-80">
                      <img src={icon159} alt="" className="popular-badge" />

                      <img src={icon86} alt="" className="icon" />

                      <div className="pack-name">Gold Plan</div>

                      <ul className="pr-feature">
                        <li>Unlimited Email</li>

                        <li>5gb Hosting</li>

                        <li>Email &amp; Live chat.</li>

                        <li>3 Domain</li>
                      </ul>

                      <div className="price">$29.99</div>

                      <div className="trial-text">up to 7 user + 1.99 per user</div>

                      <NavLink NavLink to="/"
                        className="trial-button hover-reverse-gr-bg-one gr-bg-one"
                      >
                        Sign up
                      </NavLink>

                      <div className="js-tilt-glare" style={style3}>
                        <div className="js-tilt-glare-inner" style={style4}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceSection;
