import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import EcomPNG from "../../../../images/assets/ecommerce.png";
import RealPNG from "../../../../images/assets/health.png";
import HealthPNG from "../../../../images/assets/real.png";
import MediaPNG from "../../../../images/assets/media.png";
import Finance1PNG from "../../../../images/assets/finance1.png";
import InsurancePNG from "../../../../images/assets/insurance1.png";
import SecurityPNG from "../../../../images/assets/security1.png";
import EduPNG from "../../../../images/assets/education1.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PhpPNG from "../../../../images/assets/php.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const PhpPage = () => {
  const tagLines = "We are different";
  const title = "Nothing But The Best With PHP Development Services";
  const description = `PHP is a versatile programming language that can be used to build websites and applications all over the world. Symphony development services are widely practiced and provide well-defined solutions that create apps that work smart and hard. A complete roadmap is designed rationally with a few top-skilled Symphony developers to enable you a Hastech power that gets you to the top.`;
  const services = {
    title: " Php Development Services ",
    tagLines: " High-Class Php Development With Professional Excellencee",
    items: [
      {
        title: "E-commerce",
        imageSrc: EcomPNG,
        imageAlt:
          "Php Developer In Ilford UK For Ecommerce Website",
      },
      {
        title: "Healthcare",
        imageSrc: HealthPNG,
        imageAlt: "Php Developer For Healthcare Website In Ilford UK",
      },
      {
        title: "Real Estate",
        imageSrc: RealPNG,
        imageAlt: "Php Development Agency For Real Estate Near Ilford",
      },
      {
        title: "Media",
        imageSrc: MediaPNG,
        imageAlt:
          "Php Development Agency For Media Website In Ilford UK",
      },
      {
        title: "Finance",
        imageSrc: Finance1PNG,
        imageAlt:
          "Php Development Freelancer For Finance Management In Ilford UK",
      },
      {
        title: "Insurance",
        imageSrc: InsurancePNG,
        imageAlt:
          "Php Development Agency For Insurance In Ilford UK",
      },
      {
        title: "Security",
        imageSrc: SecurityPNG,
        imageAlt: "Php Development Comapny In Ilford UK For Security Services",
      },
      {
        title: "Education",
        imageSrc: EduPNG,
        imageAlt:
          "Php Developer In Onatrio For Education Wbsite",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "PHP Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "PHP Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom PHP App Projects",
    },
  ];

  const heading = "Raising The Bar For Php Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Build Php Apps with our"
        description="While putting your mission in the limelight, we ensure our PHP solutions help you
                     speak your brand story that holds the hearts and minds of users. Our user-driven strategies
                      leverage the absolute maturity and power of PHP to fuel all needs of your business whether 
                      a basic portal or a complex solution."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={PhpPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default PhpPage;
