import React from "react";
import Header from '../../../../Common/Header'
import Footer from '../../../../Common/Footer'
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ContainerNativeTech from "../components/ContainerNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import kotlinDesign from "../../../../../images/assets/kotlin.png";
import MopePNG from "../../../../../images/assets/mope.png";
import PushPNG from "../../../../../images/assets/push.png";
import PNG876 from "../../../../../images/assets/876.png";
import SinglePNG from "../../../../../images/assets/Single.png";
import softPNG from '../../../../../images/assets/soft.png';
import extPNG from '../../../../../images/assets/ext.png';
import uiPNG from '../../../../../images/assets/ui.png';
import PNG58 from '../../../../../images/assets/58.png';
import PNG890 from '../../../../../images/assets/890.png';
import PNG984 from '../../../../../images/assets/984.png';
import PNG78 from '../../../../../images/assets/78.png';
import PNG1221 from '../../../../../images/assets/1221.png';
import PNG12 from "../../../../../images/assets/12.png";
import PNG81 from "../../../../../images/assets/81.png";
import PNG36 from "../../../../../images/assets/36.png";
import PNG38 from "../../../../../images/assets/38.png";

const KotlinPage = () => {
  const tagLines = "Our Kotlin Excellence";
  const title =
    "A Potent Kotlin App Development Company Delivering Polished App";
  const description = `HASTech stands high as an
                        Kotlin Development Company taking bold steps from 
                        designing pixel-perfect designs to intuitive
                        backends, to fill each dimension of the modern 
                        Kotlin app development realm. For the quality that
                        Android devices have, our Kotlin development services 
                        are bound to set the bar high with a product that is
                        bold, intuitive, timely and innovative.`;
  const services = {
    title: "Kotlin App Development Services",
    tagLines: " Kotlin Development With Professional Excellence",
    items: [
      {
        title: "Custom Kotlin App Development",
        imageSrc: softPNG,
        imageAlt:
          "Best Kotlin Application Developers In Oakville, Ilford UK",
      },
      {
        title: "Enterprise KOTLIN Solutions",
        imageSrc: extPNG,
        imageAlt: "Enterprise Kotlin App Developer In Oakville Ilford",
      },
      {
        title: "Kotlin app UI/UX Design",
        imageSrc: uiPNG,
        imageAlt: "Kotlin app UI/UX Design Developer In Ilford",
      },
      {
        title: "Kotlin App Consultation",
        imageSrc: PNG58,
        imageAlt: "Kotlin App Consultation Agency In Ilford UK",
      },
      {
        title: " Kotlin app Support, Optimization, and Maintenance",
        imageSrc: PNG890,
        imageAlt:
          "Kotlin app Support, Optimization, and Maintenance Provider In Oakville, Ilford UK",
      },
      {
        title: "Kotlin Widget/Extension Development",
        imageSrc: PNG78,
        imageAlt:
          "Kotlin Widget/Extension Development Freelancer In Ilford UK",
      },
      {
        title: "Kotlin App Integration",
        imageSrc: PNG984,
        imageAlt:
          "Kotlin App Integration Services Provider In Oakville, UK",
      },
      {
        title: "Kotlin App Prototyping",
        imageSrc: PNG1221,
        imageAlt: "Kotlin App Prototyping Freelancer In Oakville Ilford",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "Top Kotlin Developer Near Me Oakville, Ilford, UK",
      count: "150+",
      label: "Kotlin Experts",
    },
    {
      image: PNG81,
      alt: "Experience Freelancer For Kotlin Developer Near Me Ilford",
      count: "100+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "Kotlin App Projects Management Services In UK",
      count: "150+",
      label: "Kotlin Projects Completed",
    },
    {
      image: PNG38,
      alt: "Custom Mobile App Project Maker Neaby Ilford UK",
      count: "300+",
      label: "Custom Kotlin App Projects",
    },
  ];

  const heading = "Raising The Bar For Kotlin Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Best Quality iPhone Application Developers In Oakville, Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Best Quality iPhone Application Freelancer In Oakville, Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Best Quality iPhone Application Agency In Oakville, Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Best Quality iPhone Application Company In Oakville, Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title=" Kotlin Android App"
        description="HASTech stands high as an Kotlin Application Developers in Ilford UK taking bold steps from designing pixel-perfect designs to intuitive backends, to fill each dimension of the modern Kotlin app development realm. For the quality that Android devices have, our Best Kotlin Developers in Ilford UK are bound to set the bar high with a product that is bold, intuitive, timely and innovative."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={kotlinDesign}
        imageAlt="Best And Top Quality Kotlin Developers In Oakville Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default KotlinPage;
