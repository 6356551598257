import React from "react";
import HeroBannerSix from "./HeroBannerSix";
import Container from "./Container";
import Cmr from "./Cmr";
import DigitalMarketing from "./DigitalMarketing";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import FancyShortBanner from "../Home/FancyShortBanner";

const Service = () => {
  return (
    <>
      <Header />
      <HeroBannerSix />
      <Container />
      <Cmr />
      <DigitalMarketing />
      <FancyShortBanner />
      <Footer />
    </>
  );
};

export default Service;
