import React from 'react'

const AboutSolveSection = () => {
  return (
    <>
     <div className="we-solve-section">
        <div className="container-solve">
          <div className="we-solve-card">
            <div className="double-size-alphabet">
              <h3>We</h3>
            </div>

            <div className="we-solve-content">
              <div className="content-inner-title">
                <h4 style={{ color: 'black' }}>Solve Problems.</h4>
                <h4 style={{ color: 'black' }}>Fuel Innovation.</h4>
                <h4 style={{ color: 'black' }}>Make It Happen.</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="floating-half-shade"></div>
      </div>
    </>
  )
}

export default AboutSolveSection