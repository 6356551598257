import React from "react";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ContainerNativeTech from "../components/ContainerNativeTech";
import softPNG from "../../../../images/assets/soft.png";
import EcomPNG from "../../../../images/assets/ecommerce.png";
import SqlPNG from "../../../../images/assets/sql23.png";
import PNG890 from "../../../../images/assets/890.png";
import PNG78 from "../../../../images/assets/78.png";
import PNGmb34 from "../../../../images/assets/mb34.png";
import PNG984 from "../../../../images/assets/984.png";
import PNG58 from "../../../../images/assets/58.png";
import PNG1221 from "../../../../images/assets/1221.png";
import PNG12 from "../../../../images/assets/12.png";
import CodegniterPNG from "../../../../images/assets/codegniter.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const CodeigniterPage = () => {
  const tagLines = "Our Codeigniter Expertise";
  const title =
    "A Codeigniter Development Company That Puts Your Brand First";
  const description = `To win with digital innovation, you don't need to know about the methods
                        and processes involved in developing custom web applications. 
                        At Hastech, we take the responsibility to build a website that
                          is perfect for your customers. You are an expert in your business,
                          and we can help turn that expertise into growth with our skills.
                            We are here to help you come up with an idea for an upcoming
                            successful Codeigniter development. We work with clients to
                              define and enable ideation, from testing to monitoring,
                              from ensuring current market relevance to marketing.`;
  const services = {
    title: " Codeigniter App Development Services",
    tagLines: " Codeigniter Development With Professional Excellence",
    items: [
      {
        title: "Codeigniter Web Development",
        imageSrc: softPNG,
        imageAlt:
          "PHP CodeIgniter Web Application Development Agency In Ilford UK",
      },
      {
        title: "Customized E-Commerce Development",
        imageSrc: EcomPNG,
        imageAlt: "Customized E-Commerce Development Agency In Ilford UK",
      },
      {
        title: "Develop Web Portals with PHP Codeigniter and MySQL",
        imageSrc: SqlPNG,
        imageAlt: "Develop Web Portals with PHP Codeigniter and MySQL",
      },
      {
        title: " Easy configuration and simplicity",
        imageSrc: PNG58,
        imageAlt:
          "Easy configuration and simplicity",
      },
      {
        title: "Codeigniter Maintenance & Support",
        imageSrc: PNG890,
        imageAlt:
          "Codeigniter Maintenance & Support",
      },
      {
        title: "Codeigniter Integration with SugarCRM",
        imageSrc: PNG78,
        imageAlt:
          "Codeigniter Integration with SugarCRM",
      },
      {
        title: "Back-end for Mobile Apps",
        imageSrc: PNGmb34,
        imageAlt: "Back-end for Mobile Apps",
      },
      {
        title: "Codeigniter Social Networking Solutions",
        imageSrc: PNG1221,
        imageAlt:
          "Flutter Front-end Development Services Nearby Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "expertIcon",
      count: "15+",
      label: "Codeigniter Experts",
    },
    {
      image: PNG81,
      alt: "experienceIcon",
      count: "12+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "projectsIcon",
      count: "55+",
      label: "Codeigniter Projects Completed",
    },
    {
      image: PNG38,
      alt: "mobilePro",
      count: "300+",
      label: "Custom Codeigniter Projects",
    },
  ];

  const heading = "Raising The Bar For Codeigniter Development Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Ionic App Development Comapny In Oakville Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Ionic App Development Freelancer In Oakville Ilford UK",
    },
  ];

  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Codeigniter"
        description="A dynamic web application attuned to market needs built for your customers and processes will definitely 
                      MAKE YOU WIN the digital game. Our Codeigniter development services help you 
                      to build such full-featured dynamic web platforms. Whether you are looking 
                      to innovate, orchestrate or transform – Codeigniter developers at Hastech 
                      provide solutions committed to driving results."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={CodegniterPNG}
        imageAlt="Best Flutter App Development Freelancer In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      {/* <ContainerNativeTech/> */}
      <ContainerNativeTech services={services} />
      {/* <ServiceStateNativeTech/> */}
      <ServiceStateNativeTech stats={stats} heading={heading} />
      {/* <TestimonialNativeTech/> */}
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default CodeigniterPage;
