import React from "react";
import Header from "../../../../../src/components/Common/Header";
import Footer from "../../../../../src/components/Common/Footer";
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ContainerNativeTech from "../components/ContainerNativeTech";
import ServiceStateNativeTech from "../components/ServiceStateNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import androidDesign from "../../../../images/assets/android12.png";
import softPNG from "../../../../images/assets/soft.png";
// import extPNG from "../../../../images/assets/ext.png";
import uiPNG from "../../../../images/assets/ui.png";
import PNG58 from "../../../../images/assets/58.png";

import PNG78 from "../../../../images/assets/78.png";
import PNG1221 from "../../../../images/assets/1221.png";
import img00 from "../../../../images/assets/00.png";
import img1209 from "../../../../images/assets/1209.png";
import img28 from "../../../../images/assets/28.png";

import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";

import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";

const PayPerClick = () => {
  const tagLines = "Our Pay Per Click Excellence";
  const title =
    "A Potent Pay Per Click Development Company Delivering Polished App";
  const description = [
    "This is a digital marketing model where advertisers pay a fee each time their ad is clicked. It's a way of buying visits to your site rather than earning those visits organically through search engine optimization (SEO). Google Ads (formerly Google AdWords) is one of the most popular platforms for PPC advertising, but there are other platforms like Bing Ads, Facebook Ads, and more. Ads are displayed as soon as the campaign is set up and funded, driving traffic to the website almost immediately. Advertisers can choose specific keywords, demographics, locations, and other factors to ensure their ads reach a relevant audience. Advertisers can set daily or monthly budgets, preventing overspending. Once the budget is exhausted, the ads stop running until the budget is replenished. PPC platforms provide detailed analytics, allowing advertisers to track the performance of their campaigns, including clicks, impressions, conversions, and more. Advertisers can easily adjust their campaigns, ad copy, keywords, and settings based on performance and changing business needs.",
  ];
  const services = {
    title: "Pay Per Click Marketing Services",
    tagLines: "Pay Per Click Marketing With Professional Excellence",
    items: [
      {
        title: "Enterprise Pay Per Click Marketing",
        imageSrc: softPNG,
        imageAlt: "Best Enterprise Pay Per Click Marketing Nearby Ilford UK",
      },
      {
        title: " Pay Per Click Marketing",
        imageSrc: img00,
        imageAlt: "Pay Per Click Marketing Near Me Ilford UK",
      },
      {
        title: "Pay Per Click Marketing Migration",
        imageSrc: img1209,
        imageAlt: "Pay Per Click Marketing Migration Freelancer In Ilford UK",
      },
      {
        title: "Pay Per Click Marketing Applications",
        imageSrc: PNG58,
        imageAlt: "Best Pay Per Click Marketing Nearby Ilford UK",
      },
      {
        title: " Pay Per Click Marketing",
        imageSrc: img28,
        imageAlt:
          "Quality Pay Per Click Marketing Services Provider Near Me Ilford UK",
      },
      {
        title: " Maintenance & Support",
        imageSrc: PNG78,
        imageAlt:
          "Pay Per Click Marketing Maintenance And Support Agency In UK",
      },
      {
        title: " UI/UX App Design",
        imageSrc: uiPNG,
        imageAlt:
          "Best Pay Per Click Marketing UI/UX App Design Agency In Ilford UK",
      },
      {
        title: "Pay Per Click Marketing Strategy",
        imageSrc: PNG1221,
        imageAlt:
          "Top Pay Per Click Marketing Strategy Service Provider Nearby Ilford UK",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "Top Pay Per Click Experts Near Me Ilford UK",
      count: "20+",
      label: "PPC Experts",
    },
    {
      image: PNG81,
      alt: "Experience Freelancer For Pay Per Click Development In UK",
      count: "15+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "Pay Per Click Marketing Projects Management Services In UK",
      count: "150+",
      label: "PPC Projects Completed",
    },
    {
      image: PNG38,
      alt: "Custom Pay Per Click App Project Maker Neaby Ilford UK",
      count: "300+",
      label: "Custom PPC Projects",
    },
  ];

  const heading = "Raising The Bar For PPC Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Best Pay Per Click Marketing Agency In Oakville, Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Best Pay Per Click Marketing Agency In Oakville, Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Best Pay Per Click Marketing Agency In Oakville, Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Best Pay Per Click Marketing Agency In Oakville, Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Pay Per Click Marketing"
        description="Pay-Per-Click (PPC) marketing is a form of online advertising where advertisers pay a fee each time their ad is clicked. It's a way to drive targeted traffic to your website, and it can be an effective strategy for businesses looking to increase their online visibility and generate leads or sales. 
There are various forms of PPC ads, but the sponsored search ad is one of the most common. These adverts display when people search for things online using a search engine like Google, particularly when they make commercial searches, indicating that they are looking to buy something.
Businesses running ads in pay-per-click advertising are only charged when a user clicks on their ad, hence the name 'pay-per-click'."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={androidDesign}
        imageAlt="Best Pay Per Click Marketing Agency In Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default PayPerClick;
