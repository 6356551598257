import React from "react";

const AboutContainerFluid = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="color1">
          <div className="container-with mini-container left-sq">
            <div className="sec-tag-lines">
              <span style={{ color: "white", marginBottom: "40px" }}>
                Grow With Us
              </span>
              <h2
                style={{ marginBottom: "54px", marginTop: "40px" }}
                className="font-weight-bold visitors-to-customers"
              >
                With the strength of right people, experience and desire, any
                business is destined for growth.
              </h2>
            </div>

            <div className="sec-description mt-1">
              <div>
                <div>
                  <p>
                  HASTech is a UK technology company based in Ilford. 
                  We have assisted startups and businesses in bridging their VISION and REALITY. 
                  We collaborate with clients to design, develop, and implement smart technology solutions.
                  With our in-house team of experts, we provide world-class software development and 
                  consulting services for web, mobile, and cloud applications.
                  </p>

                  <p>
                    Businesses now rely on us for growth, extend their existing
                    resources with our Agile teams, and pace their time to
                    success. We are leading technology disruption with more than
                    50+ employees and working globally.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContainerFluid;
