import React from "react";
import Header from '../../../Common/Header'
import Footer from '../../../Common/Footer'
import HeroBannerSixNativeTech from "../components/HeroBannerSixNativeTech";
import ContainerMiniLeftNativeTech from "../components/ContainerMiniLeftNativeTech";
import ContainerNativeTech from "../components/ContainerNativeTech";
import TestimonialSection from "../components/TestimonialSection";
import ServiceStateNativeTech from '../components/ServiceStateNativeTech' 
import kotlinDesign from "../../../../images/assets/kotlin.png";
import MopePNG from "../../../../images/assets/mope.png";
import PushPNG from "../../../../images/assets/push.png";
import PNG876 from "../../../../images/assets/876.png";
import SinglePNG from "../../../../images/assets/Single.png";
import softPNG from '../../../../images/assets/soft.png';
import extPNG from '../../../../images/assets/ext.png';
import uiPNG from '../../../../images/assets/ui.png';
import PNG58 from '../../../../images/assets/58.png';
import PNG890 from '../../../../images/assets/890.png';
import PNG984 from '../../../../images/assets/984.png';
import PNG78 from '../../../../images/assets/78.png';
import PNG1221 from '../../../../images/assets/1221.png';
import PNG12 from "../../../../images/assets/12.png";
import PNG81 from "../../../../images/assets/81.png";
import PNG36 from "../../../../images/assets/36.png";
import PNG38 from "../../../../images/assets/38.png";
import mobile67 from "../../../../images/assets/mobile67.png";

const MobileAppDesignPage = () => {
  const tagLines = "Our Mobile App Design Excellence";
  const title =
    "With Agile Methodology At The Core Our Mobile App Design Development Services Are At The Best";
  const description = `Mobile app users don't just "point-click-buy" with them. As a company, you need to install your brand values 
  in people and develop experiences that they begin to like. To achieve the objectives of mobile app design, we work on a process that 
  is innovative, user-centric, accepted, and methodical.
   Our design enhances user mindsets and solidifies the relationship with the brand.`;
  const services = {
    title: "Mobile App Design Development Services",
    tagLines: " Mobile App Design Development With Professional Excellence",
    items: [
      {
        title: "Custom Mobile App Development",
        imageSrc: softPNG,
        imageAlt:
          "Best Mobile Application Developers In Oakville, Ilford UK",
      },
      {
        title: "Enterprise Mobile App Solutions",
        imageSrc: extPNG,
        imageAlt: "Enterprise Mobile App Developer In Oakville Ilford",
      },
      {
        title: "Mobile app UI/UX Design",
        imageSrc: uiPNG,
        imageAlt: "Mobile app UI/UX Design Developer In Ilford",
      },
      {
        title: "Mobile App Consultation",
        imageSrc: PNG58,
        imageAlt: "Mobile App Consultation Agency In Ilford UK",
      },
      {
        title: "Mobile app Support, Optimization, and Maintenance",
        imageSrc: PNG890,
        imageAlt:
          "Mobile app Support, Optimization, and Maintenance Provider In Oakville, Ilford UK",
      },
      {
        title: "Mobile Widget/Extension Development",
        imageSrc: PNG78,
        imageAlt:
          "Mobile Widget/Extension Development Freelancer In Ilford UK",
      },
      {
        title: "Mobile App Integration",
        imageSrc: PNG984,
        imageAlt:
          "Mobile App Integration Services Provider In Oakville, UK",
      },
      {
        title: "Mobile App Prototyping",
        imageSrc: PNG1221,
        imageAlt: "Mobile App Prototyping Freelancer In Oakville Ilford",
      },
    ],
    buttonText: "Inquire Now",
    buttonLink: "https://hastechsolutions.ca/contact.html",
  };
  const stats = [
    {
      image: PNG12,
      alt: "Top Kotlin Developer Near Me Oakville, Ilford, UK",
      count: "150+",
      label: "Mobile App Design Experts",
    },
    {
      image: PNG81,
      alt: "Experience Freelancer For Kotlin Developer Near Me Ilford",
      count: "100+",
      label: "Years Of Experience",
    },
    {
      image: PNG36,
      alt: "Kotlin App Projects Management Services In UK",
      count: "150+",
      label: "Mobile App Design Projects Completed",
    },
    {
      image: PNG38,
      alt: "Custom Mobile App Project Maker Neaby Ilford UK",
      count: "300+",
      label: "Custom Mobile App Design Projects",
    },
  ];

  const heading = "Raising The Bar For Mobile App Design Expertise";

  const testimonials = [
    {
      company: "Anyvision",
      content:
        "Anyvision has broken all records with its flawless face recognition technology solutions. The leading company provides security solutions for government agencies, private security. With our development endeavors, we brought the concept of retrieving information about criminal persons.",
      image: MopePNG,
      alt: "Best Quality iPhone Application Developers In Oakville, Ilford UK",
    },
    {
      company: "Enterprise E-Commerce",
      content:
        "Nutranext is a leading GMP-certified manufacturer of supplements with over 30 years of experience in the production and marketing of nutritional products. Nutranext is committed to providing high-quality, purity-controlled supplements at different stages and different lifestyles.",
      image: PushPNG,
      alt: "Best Quality iPhone Application Freelancer In Oakville, Ilford UK",
    },
    {
      company: "Glitty",
      content:
        "Glitty is an app that helps users to add glitter to various matte-based surfaces and pictures. Our expert app developers at Hashtech created an excellent user interface and experience. Today, Glitty is ranking number one on Apple's store in the Photo and video category.",
      image: PNG876,
      alt: "Best Quality iPhone Application Agency In Oakville, Ilford UK",
    },
    {
      company: "Rogi",
      content:
        "ROGI is a return on gathering information product that enables companies and their teams to be highly organized and extremely productive. ROGI has a facility that keeps track of everything you do for your clients and stores it in one central location, accessible by phone, tablet, or desktop.",
      image: SinglePNG,
      alt: "Best Quality iPhone Application Company In Oakville, Ilford UK",
    },
  ];
  return (
    <>
      <Header />
      <HeroBannerSixNativeTech
        title="Mobile App Design"
        description="Harness the power of Mobile App Design framework with our Mobile App Design development services to meet
                      the full spectrum of your business requirements from a small portal to large enterprise
                      solution with complex and thriving challenges. With the strong virtue and high-experience
                      of our Mobile App Design developers develop websites with uncompromising performance and strong backend."
        buttonText="Inquire Now"
        buttonLink="https://hastechsolutions.ca/contact.html"
        imageSrc={mobile67}
        imageAlt="Best And Top Quality Kotlin Developers In Oakville Ilford UK"
      />
      <ContainerMiniLeftNativeTech
        tagLines={tagLines}
        title={title}
        description={description}
      />
      <ContainerNativeTech services={services} />
      <ServiceStateNativeTech stats={stats} heading={heading} />
      <TestimonialSection testimonials={testimonials} />;
      <Footer />
    </>
  );
};

export default MobileAppDesignPage;
