import React from 'react'
import ThemeHeroBanner from './ThemeHeroBanner'
import FeatureTwelve from './FeatureTwelve'
import FeatureFourteen from './FeatureFourteen'
import FancyPortfolioOne from './FancyPortfolioOne'
import ClientFeedbackSlider from './ClientFeedbackSlider'
import PriceSection from './PriceSection'
import FancyShortBanner from './FancyShortBanner'
import Footer from '../Common/Footer'
import Header from '../Common/Header'


const MainPageWrapper = () => {
  return (
    <div>
      <Header/>
      <ThemeHeroBanner/>
      <FeatureTwelve/>
      <FeatureFourteen/>
      <FancyPortfolioOne/>
      <ClientFeedbackSlider/>
      <PriceSection/>
      <FancyShortBanner/>
      <Footer/>
    </div>
  )
}

export default MainPageWrapper