import "./App.css";

import Approuter from "./routers/AppRouter";

function App() {
  return (
    <>
      <Approuter />
    </>
  );
}

export default App;
