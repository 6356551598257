import React, { useState } from "react";
import img74 from "../../images/media/img_74.jpg";
import w1 from "../../images/work/w1.jpg";
import w2 from "../../images/work/w2.jpg";
import img76 from "../../images/media/img_76.jpg";
import w3 from "../../images/work/w3.jpg";
import w4 from "../../images/work/w4.jpg";
import w5 from "../../images/work/w5.jpg";
import img75 from "../../images/media/img_75.jpg";
import img73 from "../../images/media/img_73.jpg";
import s1 from "../../images/social/s1.jpg";
import s2 from "../../images/social/s2.jpg";
import s3 from "../../images/social/s3.jpg";
import s4 from "../../images/social/s4.jpg";
import img77 from "../../images/media/img_77.jpg";
import img78 from "../../images/media/img_78.jpg";
import img79 from "../../images/media/img_79.jpg";
import web1 from "../../images/website/website1.jpg"
import web2 from "../../images/website/website2.jpg"
import web3 from "../../images/website/website3.jpg"
import web4 from "../../images/website/website4.jpg"
import imgcan from "../../images/media/imagecanada.jpg"
import app1 from "../../images/media/app1.jpg"
import app2 from "../../images/media/app2.jpg"
import app3 from "../../images/media/app3.jpg"
import app4 from "../../images/media/app4.jpg"


const RowGallery = () => {
  const [showImage, setShowImage] = useState(false);
  const [enlargedImageSrc, setEnlargedImageSrc] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");

  const handleImageClick = (src) => {
    setEnlargedImageSrc(src);
    setShowImage(true);
  };

  const handleCloseClick = () => {
    setShowImage(false);
    setEnlargedImageSrc("");
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const filterImages = () => {
    switch (selectedFilter) {
      case "websites":
        return (
          <>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w1)}>
                <img
                  src={web1}
                  alt="Freelance Projects In Front End And Backend Technology In UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w2)}>
                <img
                  src={web2}
                  alt="Apps Developer Freelancer In Ilford"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w4)}>
                <img
                  src={web3}
                  alt="App Design Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w5)}>
                <img
                  src={web4}
                  alt="Front End Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
          </>
        );
      case "apps":
        return (
          <>
            
            <li className="apps">
              <a href="#" onClick={() => handleImageClick(imgcan)}>
                <img
                  src={imgcan}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="apps">
              <a href="#" onClick={() => handleImageClick(img73)}>
                <img
                  src={img73}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="apps">
              <a href="#" onClick={() => handleImageClick(img78)}>
                <img
                  src={img78}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="apps">
              <a href="#" onClick={() => handleImageClick(img79)}>
                <img
                  src={img79}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            {/* <li className="apps">
              <a href="#" onClick={() => handleImageClick(app3)}>
                <img
                  src={app3}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li> */}
            {/* <li className="apps">
              <a href="#" onClick={() => handleImageClick(app4)}>
                <img
                  src={app4}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li> */}
           
          </>
        );
      case "social-media":
        return (
          <>
            {/* <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s1)}>
                <img
                  src={s1}
                  alt="App Design Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s2)}>
                <img
                  src={s2}
                  alt="Front End Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s3)}>
                <img
                  src={s3}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s4)}>
                <img
                  src={s4}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s2)}>
                <img
                  src={s2}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(s1)}>
                <img
                  src={s1}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li> */}
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(app1)}>
                <img
                  src={app1}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(app2)}>
                <img
                  src={app2}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(app3)}>
                <img
                  src={app3}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="social-media">
              <a href="#" onClick={() => handleImageClick(app4)}>
                <img
                  src={app4}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
          </>
        );
      default:
        return (
          <>
            <li className="apps">
              <a href="#" onClick={() => handleImageClick(img74)}>
                <img
                  src={img74}
                  alt="Front End Design Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w1)}>
                <img
                  src={w1}
                  alt="Freelance Projects In Front End And Backend Technology In UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w2)}>
                <img
                  src={w2}
                  alt="Apps Developer Freelancer In Ilford"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(w3)}>
                <img
                  src={w3}
                  alt="Beck End Developer Freelancer In Ilford"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(app1)}>
                <img
                  src={app1}
                  alt="App Design Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(app2)}>
                <img
                  src={app2}
                  alt="Front End Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(app3)}>
                <img
                  src={app3}
                  alt="Additional Image 1"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(app4)}>
                <img
                  src={app4}
                  alt="Additional Image 2"
                />
              </a>
            </li>
            <li className="apwebsitesps">
              <a href="#" onClick={() => handleImageClick(imgcan)}>
                <img
                  src={imgcan}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
            <li className="websites">
              <a href="#" onClick={() => handleImageClick(img73)}>
                <img
                  src={img73}
                  alt="Apps Developer Freelancer In Ilford UK"
                />
              </a>
            </li>
          </>
        );
    }
  };

  return (
    <>
      <div className="container">
        <div className="row gallery-filter">
          <nav>
            <ul>
              <li
                className={selectedFilter === "all" ? "current" : ""}
                onClick={() => handleFilterClick("all")}
              >
                <a href="#">All projects</a>
              </li>
              <li
                className={selectedFilter === "websites" ? "current" : ""}
                onClick={() => handleFilterClick("websites")}
              >
                <a href="#">Websites</a>
              </li>
              <li
                className={selectedFilter === "apps" ? "current" : ""}
                onClick={() => handleFilterClick("apps")}
              >
                <a href="#">Apps</a>
              </li>
              <li
                className={selectedFilter === "social-media" ? "current" : ""}
                onClick={() => handleFilterClick("social-media")}
              >
                <a href="#">Social-media</a>
              </li>
            </ul>
          </nav>

          <div id="projects">
            <ul id="gallery">
              {filterImages()}
            </ul>
          </div>

          <div className="gallery" style={{ display: showImage ? "block" : "none" }}>
            <a className="close" href="#" onClick={handleCloseClick}>
              <i>
                <span className="bar"></span>
                <span className="bar"></span>
              </i>
            </a>
            <img src={enlargedImageSrc} alt="Enlarged Image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default RowGallery;
