import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img75 from "../../images/media/img_75.jpg";
import img76 from "../../images/media/img_76.jpg";
import img77 from "../../images/media/img-77.jpg";
import img235 from "../../images/media/img235.jpg";
import img71 from "../../images/media/img_71.jpg";
import icon82 from "../../images/icon/82.svg";
import icon83 from "../../images/icon/83.svg";
const FancyPortfolioOne = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 3000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides at a time
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show 2 slides at a time on smaller screens (e.g., tablets)
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Show 1 slide at a time on smaller screens (e.g., mobile)
        },
      },
    ],
  };

  const renderSlideItems = () => {
    const slideItems = [
      { id: 1, imgSrc: img75, altText: "Fat2Fuel(F2F)- An App Build By Of Hastech Solutions" },
      { id: 2, imgSrc: img76, altText: "Immigration World- An App Build By Of Hastech Solutions" },
      { id: 3, imgSrc: img77, altText: "Astrosmas Web and App Build By Of Hastech Solutions" },
      { id: 4, imgSrc: img235, altText: "IPA- An App Build By Of Hastech Solutions" },
    ];

    return slideItems.map((item) => (
      <div className="item" key={item.id}>
        <div className="img-meta" style={{ maxWidth: "200px" }}>
          <img src={item.imgSrc} alt={item.altText} />
        </div>
      </div>
    ));
  };

  return (
    <div className="fancy-portfolio-one mt-180 md-mt-100" id="product">
      <div className="container">
        <div className="title-style-six">
          <h2>
            Our <span>Portfolio</span>
          </h2>
        </div>
      </div>

      <Slider {...settings} ref={sliderRef}>
        {renderSlideItems()}
      </Slider>

      <div className="slider-arrows d-flex justify-content-center">
        <div
          className="prev_c d-flex align-items-center justify-content-center slick-arrow"
          onClick={() => sliderRef.current?.slickPrev()}
        >
          <img src={icon82} alt="" />
        </div>
        <div
          className="next_c d-flex align-items-center justify-content-center slick-arrow"
          onClick={() => sliderRef.current?.slickNext()}
        >
          <img src={icon83} alt="" />
        </div>
      </div>
    </div>
  );
};



export default FancyPortfolioOne;