import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 

// import Header from "../components/Common/Header";
// import Footer from "../components/Common/Footer";
// import MainPageWrapperRouter from "./MainPageWrapperRouter";
// import AboutRouter from "./AboutRouter";
// import ServiceRouter from "./ServiceRouter";
import Portfolio from "../components/Portfolio/Portfolio";
import Contact from "../components/Contact/Contact";
import MobileAppDesignPage from "../components/Service/UiUxTechnologies/MobileAppDesign/MobileAppDesignPage";
import ResponsiveDesignPage from "../components/Service/UiUxTechnologies/ResponsiveDesign/ResponsiveDesignPage";
import DesignPrototypingPage from "../components/Service/UiUxTechnologies/DesignPrototyping/DesignPrototypingPage";
import PsdtoHtmlPage from "../components/Service/UiUxTechnologies/PsdToHtml/PsdtoHtmlPage";

import IphonePage from "../components/Service/MobileTechnologies/NativeTechnologies/iphone/IphonePage"
import AndroidPage from "../components/Service/MobileTechnologies/NativeTechnologies/android/AndroidPage";
import KotlinPage from "../components/Service/MobileTechnologies/NativeTechnologies/kotlin/KotlinPage";
import IpadPage from "../components/Service/MobileTechnologies/NativeTechnologies/ipad/IpadPage";


import IonicPage from "../components/Service/MobileTechnologies/CrossPlatformTechnologies/ionic/IonicPage";
import FlutterPage from "../components/Service/MobileTechnologies/CrossPlatformTechnologies/flutter/FlutterPage";
import ReactNative from "../components/Service/MobileTechnologies/CrossPlatformTechnologies/reactNative/ReactNative"
import Pwa from "../components/Service/MobileTechnologies/CrossPlatformTechnologies/pwa/Pwa"
import Xamarin from "../components/Service/MobileTechnologies/CrossPlatformTechnologies/xamarin/Xamarin"


import PhpPage from "../components/Service/WebTechnologies/php/PhpPage";
import CodeigniterPage from "../components/Service/WebTechnologies/codeigniter/CodeigniterPage";
import LaravelPage from "../components/Service/WebTechnologies/laravel/LaravelPage";
import ZendPage from "../components/Service/WebTechnologies/zend/ZendPage";
import YiiPage from "../components/Service/WebTechnologies/yii/YiiPage";
import SymfonyPage from "../components/Service/WebTechnologies/symfony/SymfonyPage";
import MainPageWrapper from "../components/Home/MainPageWrapper";


import Angular from "../components/Service/JavascriptTechnologies/angular/Angular"
import NodeJs from "../components/Service/JavascriptTechnologies/nodeJs/NodeJs"
import VueJs from "../components/Service/JavascriptTechnologies/vueJs/VueJs"
import ReactJs from "../components/Service/JavascriptTechnologies/reactJs/ReactJs"
import MeteorJs from "../components/Service/JavascriptTechnologies/meteorJs/MeteorJs"
import ExpressJs from "../components/Service/JavascriptTechnologies/expressJs/ExpressJs"

import SocialMediaMarketing from "../components/Service/DigitalMarketing/socialMediaMarketing/SocialMediaMarketing"
import SocialMediaOptimization from "../components/Service/DigitalMarketing/socialMediaOptimization/SocialMediaOptimization"
import SearchEngineOptimization from "../components/Service/DigitalMarketing/searchEngineOptimization/SearchEngineOptimization"
import PayPerClick from "../components/Service/DigitalMarketing/payPerClick/PayPerClick"

import AngularPage from "../components/Service/CloudTechnologies/angularpage/AngularPage";
import VuePage from "../components/Service/CloudTechnologies/vuepage/VuePage";
import ReactPage from "../components/Service/CloudTechnologies/reactpage/ReactPage";

import Service from "../components/Service/Service";
import About from "../components/About/About";
import ScrollToTop from "./ScrollToTop";
import ThankYou from "../components/Contact/ThankYou";
const AppRouter = () => {
  return (
    <div className="main-page-wrapper font-gordita dark-style lg-container">
    <div className="font-gordita dark-style lg-container">
      <Router>
       
        <ScrollToTop/>
        <Routes>
        
          {/* <Route exact path="/" element={<MainPageWrapperRouter />} /> */}
          <Route exact path="/" element={<MainPageWrapper />} />
          {/* <Route path="/about" element={<AboutRouter />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/service/*" element={<ServiceRouter />} /> */}
          <Route path="/service" element={<Service/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/portfolio" element={<Portfolio />} />

        
        {/* <Route path="/iphone" element={<IphonePage />} /> */}
        <Route path="/iphone" element={<IphonePage />} />
        <Route path='/android' element={<AndroidPage/>}/>
        <Route path='/kotlin' element={<KotlinPage/>}/>
         <Route path='/ipad' element={<IpadPage/>}/>
         <Route path='/flutter' element={<FlutterPage/>}/>
         <Route path='/ionic' element={<IonicPage/>}/>

        
         <Route path="/ionic" element={<IonicPage />}/>
         <Route path="/reactnative" element={<ReactNative />}/>
         <Route path="/flutter" element={<FlutterPage />}/>
         <Route path="/pwa" element={<Pwa />}/>
         <Route path="/xamarin" element={<Xamarin />}/>

       
         <Route path="/angular" element={<Angular />}/>
         <Route path="/nodejs" element={<NodeJs />}/>
         <Route path="/vuejs" element={<VueJs/>}/>
         <Route path="/reactjs" element={<ReactJs />}/>
         <Route path="/meteorjs" element={<MeteorJs />}/>
         <Route path="/expressjs" element={<ExpressJs/>}/>
         <Route path="/searchengineoptimization" element={<SearchEngineOptimization/>}/>
         <Route path="/socialmediamarketing" element={<SocialMediaMarketing />}/>
         <Route path="/socialmediaoptimizing" element={<SocialMediaOptimization />}/>
         <Route path="/payperclick" element={<PayPerClick />}/>
         


        
          <Route path='/php' element={<PhpPage/>}/>
          <Route path='/codeigniter' element={<CodeigniterPage/>}/>
          <Route path='/laravel' element={<LaravelPage/>}/>
          <Route path='/zend' element={<ZendPage/>}/>
          <Route path='/yii' element={<YiiPage/>}/>
          <Route path='/symfony' element={<SymfonyPage/>}/>


          
          <Route path="/mobileappdesign" element={<MobileAppDesignPage />} />
          <Route path="/responsivedesign" element={<ResponsiveDesignPage />} />
          <Route path="/designprototyping" element={<DesignPrototypingPage />} />
          <Route path="/psdtohtml" element={<PsdtoHtmlPage />} />

          <Route path="/angularpage" element={<AngularPage/>}/>
          <Route path="/vuepage" element={<VuePage/>}/>
          <Route path="/reactpage" element={<ReactPage/>}/>
        </Routes>
       
      </Router>
    </div>
    </div>
  );
};

export default AppRouter;